import {
  SEARCH_PO_LIST_DATA_START,
  SEARCH_PO_LIST_DATA_SUCCESS,
  SEARCH_PO_LIST_DATA_ERROR,
  GET_PO_STATUS_START,
  GET_PO_STATUS_SUCCESS,
  GET_PO_STATUS_ERROR
} from '../constantTypes';

const initialState = {
  loading: true,
  list: false,
  errors: [],
  items: [],
  value: ""
};

const poReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_PO_LIST_DATA_START:
    return {
      ...state,
      loading: true,
    }
    case SEARCH_PO_LIST_DATA_SUCCESS:
    return {
      ...state,
      loading: false,
      list: action.payload,
      items: action.items,
      value: action.value
    }
    case SEARCH_PO_LIST_DATA_ERROR:
    return {
      ...state,
      errors: action.payload
    }
    case GET_PO_STATUS_START:
    return {
      ...state,
      loading: true
    }
    case GET_PO_STATUS_SUCCESS:
    let newItem = action.payload
    let items ={ ...state.items};
    let newData = items.map(i => {
      if(i.po_id === newItem.po_id) {
        i = newItem
      }
    })
    return {
      ...state,
      loading: false,
      items: newData
    }
    case GET_PO_STATUS_ERROR:
    return {
      ...state,
      errors: action.payload
    }
    default:
     return state;
  }

};

export default poReducer;
