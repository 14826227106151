import { combineReducers } from 'redux';
import exampleReducer from './exampleReducer';
import appReducer from './appReducer';
import stockListReducer from './stockList';
import vendorReducer from './vendorReducer';
import partsApprovalReducer from './partsApproval';
import authReducer from './auth';
import createRFQReducer from './createRFQ';
import purchaseReducer from './purchase';
import otherReducer from './other';
import { reducer as toastrReducer } from 'react-redux-toastr';
import taxListReducer from './taxList';
import countriesReducer from './countries';
import modalReducer from './modals';
import qauotesReducer from './quotesList';
import childQuotesReducer from './childQuotesList';
import tierReducer from './tierManagementReducer';
import jeneralArraysReducer from './jeneralArraysReducer';
import labourTypeReducer from './labourTypeReducer';
import materialDetailsReducer from './materialDetailsReducer';
import poReducer from './po';
import rfqReducer from './rfq';
import currencyReducer from './currency';
import taxesReducer from './taxes';
import clientsReducer from './clients';
import jobReducer from './jobReducer';
import resourcesReducer from './resourcesReducer';
import calendarReducer from './calendarReducer';
import usersReducer from "./usersReducer";
import receivingReducer from "./receivingReducer";
import errorReducer from "./globalErrors";
import timeManagementReducer from './timeManagementReducer';

const rootReducer = combineReducers({
  errors: errorReducer,
  example: exampleReducer,
  app: appReducer,
  stockList: stockListReducer,
  vendors: vendorReducer,
  partsApproval: partsApprovalReducer,
  login: authReducer,
  createRFQ: createRFQReducer,
  purchase: purchaseReducer,
  toastr: toastrReducer,
  countries: countriesReducer,
  other: otherReducer,
  modal: modalReducer,
  quotes: qauotesReducer,
  childQuotes: childQuotesReducer,
  tiers: tierReducer,
  lists: jeneralArraysReducer,
  labourType: labourTypeReducer,
  materialDetails: materialDetailsReducer,
  po: poReducer,
  rfq: rfqReducer,
  currency: currencyReducer,
  taxes: taxesReducer,
  clients: clientsReducer,
  jobsPage: jobReducer,
  calendar: calendarReducer,
  resources: resourcesReducer,
  users: usersReducer,
  receiving: receivingReducer,
  timeManagement: timeManagementReducer,
});

export default rootReducer;
