import {
    ADD_NEW_LABOUR_TYPE_START,
    ADD_NEW_LABOUR_TYPE_SUCCESS,
    ADD_NEW_LABOUR_TYPE_ERROR,
    GET_LABOUR_TYPE_LIST_START,
    GET_LABOUR_TYPE_LIST_SUCCES,
    GET_LABOUR_TYPE_LIST_ERROR,
    LABOUR_TYPE_CALCULATIONS_START,
    LABOUR_TYPE_CALCULATIONS_SUCCES,
    LABOUR_TYPE_CALCULATIONS_ERROR,
    GET_QUOTE_STATISTIC_START,
    GET_QUOTE_STATISTIC_SUCCESS,
    GET_QUOTE_STATISTIC_ERROR,
    EDIT_LABOUR_TYPE_LIST_START,
    EDIT_LABOUR_TYPE_LIST_SUCCES,
    EDIT_LABOUR_TYPE_LIST_ERROR,
    ADD_LABOUR_TYPE_FROM_TIER_START,
    ADD_LABOUR_TYPE_FROM_TIER_SUCCESS,
    ADD_LABOUR_TYPE_FROM_TIER_ERROR,
    SYNC_LABOUR_TYPE_DATA,
    SEARCH_LABOUR_TYPE_START,
    SEARCH_LABOUR_TYPE_SUCCESS,
    SEARCH_LABOUR_TYPE_ERROR,
} from '../constantTypes';

const initialState = {
    loading: true,
    list: [],
    errors: [],
    calculations: [],
    quoteStatistic: [],
    searchField: false
};

const labourTypeReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_NEW_LABOUR_TYPE_START:
            return {
                ...state,
                loading: true,
            };
        case ADD_NEW_LABOUR_TYPE_SUCCESS:
            return {
                ...state,
                list: state.list.concat([...action.payload]),
                loading: false,
                errors: [],
            };
        case ADD_NEW_LABOUR_TYPE_ERROR:
            return {
                ...state,
                errors: action.payload,
            };
        case GET_LABOUR_TYPE_LIST_START:
            return {
                ...state,
                loading: true,
                list: [],
            };
        case GET_LABOUR_TYPE_LIST_SUCCES:
            return {
                ...state,
                list: action.payload,
                loading: false,
                errors: [],
            };
        case GET_LABOUR_TYPE_LIST_ERROR:
            return {
                errors: action.payload,
                loading: false,
            };
        case LABOUR_TYPE_CALCULATIONS_START:
            return {
                ...state,
                loading: true,
            };
        case LABOUR_TYPE_CALCULATIONS_SUCCES:
            return {
                ...state,
                calculations: action.payload,
                loading: false,
            };
        case LABOUR_TYPE_CALCULATIONS_ERROR:
            return {
                ...state,
                errors: action.payload,
                loading: false,
            };
        case GET_QUOTE_STATISTIC_START:
            return {
                ...state,
                loading: true,
            };
        case GET_QUOTE_STATISTIC_SUCCESS:
            return {
                ...state,
                quoteStatistic: action.payload,
                loading: false,
            };
        case GET_QUOTE_STATISTIC_ERROR:
            return {
                ...state,
                errors: action.payload,
            };

        case EDIT_LABOUR_TYPE_LIST_ERROR:
            return {
                ...state,
                errors: action.payload,
            };
        case EDIT_LABOUR_TYPE_LIST_START:
            return {
                ...state,
                loading: true,
            };
        case EDIT_LABOUR_TYPE_LIST_SUCCES:
            const copy = [...state.list];
            const data = copy.items.map(item =>
                item.id === action.payload.id ? action.payload : item
            );
            return {
                ...state,
                list: data,
            };
        case ADD_LABOUR_TYPE_FROM_TIER_START:
            return {
                ...state,
                errors: action.payload,
            };
        case ADD_LABOUR_TYPE_FROM_TIER_SUCCESS:
            const copyForTier = [...state.list];
            const resultForTier = copyForTier.concat(action.payload);
            return {
                ...state,
                list: resultForTier,
                errors: [],
            };
        case ADD_LABOUR_TYPE_FROM_TIER_ERROR:
            return {
                ...state,
                errors: action.payload,
            };
        case SYNC_LABOUR_TYPE_DATA:
            let item = action.payload.data;

            let index = undefined;

            state.list.items.filter((itemFromData, key) => {
                if (itemFromData.id === action.payload.id) {
                    index = key;
                }

                return index;
            });

            if (index !== undefined) {
                let updated_item = {
                    ...state.list.items[index],
                    cost: item.cost,
                    dt_rate: item.dt_rate,
                    hours_per_part: item.hours_per_part,
                    ot_rate: item.ot_rate,
                    labour_price: item.labour_price,
                    labour_cost: item.labour_cost,
                    qty_of_part: item.qty_of_part,
                    reg_rate: item.reg_rate,
                };

                let newList = { ...state.list };
                newList.items[index] = updated_item;

                return {
                    ...state,
                    list: newList,
                };
            }

            return {
                ...state,
            };

            case SEARCH_LABOUR_TYPE_START:
                return {
                    ...state,
                    loading: true,
                    list: [],
                };
            case SEARCH_LABOUR_TYPE_SUCCESS:
                return {
                    ...state,
                    list: action.payload,
                    loading: false,
                    searchField: action.searchField,
                };
            case SEARCH_LABOUR_TYPE_ERROR:
                return {
                    errors: action.payload,
                };

        default:
            return state;
    }
};

export default labourTypeReducer;
