import {
	CHANGE_TAB_INDEX,
	CHANGE_MODAL_TYPE,
	DELETE_JOB_ERROR,
	DELETE_JOB,
	SET_JOB_QUOTE_FIELDS,
	SET_JOB_TM_FIELDS,
	GET_ALL_JOBS_DATA_INIT,
	GET_ALL_JOBS_DATA_SUCCESS,
	GET_ALL_JOBS_DATA_FAILURE,
	GET_PARENT_QUOTES_LIST,
	GET_CHILDREN_QUOTES,
	GET_CURRENT_JOB,
	GET_MANAGERS_LIST,
	GET_CUSTOMERS_LIST_DATA,
	GET_TM_JOB_LIST,
	PUT_NEW_JOB_INIT,
	PUT_NEW_JOB_SUCCESS,
	RESET_JOB_FIELDS,
	RESET_JOB_QUOTE_FIELDS,
	PUT_NEW_JOB_FAILURE,
	RESET_TM_JOB_FIELDS,
	SET_JOB_STATUS_START,
	SET_JOB_STATUS_SUCCESS,
	SET_JOB_STATUS_ERROR,
	GET_ALL_INTERNAL_JOB_INIT,
	GET_ALL_INTERNAL_JOB_SUCCESS,
	GET_ALL_INTERNAL_JOB_FAILURE,
	SEARCH_JOB_START,
	SEARCH_JOB_SUCCESS,
	SEARCH_JOB_ERROR,
	DELETE_JOB_START,

	GET_ALL_JOB_DETAILS_INIT,
	GET_ALL_JOB_DETAILS_SUCCESS,
	GET_ALL_JOB_DETAILS_FAILURE,
	PUT_NEW_JOB_DETAILS_INIT,
	PUT_NEW_JOB_DETAILS_SUCCESS,
	PUT_NEW_JOB_DETAILS_FAILURE,
	GET_JOB_STATISTIC_INIT,
	GET_JOB_STATISTIC_SUCCESS,
	GET_JOB_STATISTIC_FAILURE,
	SET_LABOUR_TYPE_FIELDS,
	RESET_LABOUR_TYPE_FIELDS,
	DELETE_JOB_DETAILS_START,
	DELETE_JOB_DETAILS_SUCCESS,
	DELETE_JOB_DETAILS_ERROR,

	SET_LABOUR_STATUS_START,
	SET_LABOUR_STATUS_SUCCESS,
	SET_LABOUR_STATUS_ERROR,

	GET_INTERNAL_JOB_DETAILS_INIT,
	GET_INTERNAL_JOB_DETAILS_SUCCESS,
	GET_INTERNAL_JOB_DETAILS_FAILURE,

	SEARCH_ALL_JOBS_DETAILS_START,
	SEARCH_ALL_JOBS_DETAILS_SUCCESS,
	SEARCH_ALL_JOBS_DETAILS_ERROR,

	SEARCH_INTERNAL_JOBS_DETAILS_START,
	SEARCH_INTERNAL_JOBS_DETAILS_SUCCESS,
	SEARCH_INTERNAL_JOBS_DETAILS_ERROR,

	GET_JOB_ITEM_INIT,
	GET_JOB_ITEM__SUCCESS,
	GET_JOB_ITEM__FAILURE,

	GET_AVAILABLE_JOB_NUMBER_INIT,
	GET_AVAILABLE_JOB_NUMBER_SUCCESS,
	GET_AVAILABLE_JOB_NUMBER_FAILURE,
	GET_AVAILABLE_JOB_NUMBER_TM_INIT,
	GET_AVAILABLE_JOB_NUMBER_TM_SUCCESS,
	GET_AVAILABLE_JOB_NUMBER_TM_FAILURE,
} from '../constantTypes';

import {combineReducers} from 'redux';

function modalTypeReducer(state = 1, {type, payload}) {
	switch (type) {
		case CHANGE_MODAL_TYPE: {
			return payload;
		}

		default:
			return state;
	}
}

function tabIndexReducer(state = 0, {type, payload}) {
	switch (type) {
		case CHANGE_TAB_INDEX: {
			return payload;
		}
		default:
			return state;
	}
}
const availableNumberState = {
	loading: false,
	loaded: false,
	error: false,
	data: {},
};

function availableNumberReducer(state = availableNumberState, {type, payload}) {
	switch (type) {
		case GET_AVAILABLE_JOB_NUMBER_INIT:
			return {...state, loading: true, loaded: false, error: false};
		case GET_AVAILABLE_JOB_NUMBER_SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
				error: false,
				data: payload,
			};
		case GET_AVAILABLE_JOB_NUMBER_FAILURE:
			return {...state, loading: false, loaded: false, error: true};
		default:
			return state;
	}
}

const availableNumberTMState = {
	loading: false,
	loaded: false,
	error: false,
	data: {},
};

function availableNumberTMReducer(state = availableNumberTMState, {type, payload}) {
	switch (type) {
		case GET_AVAILABLE_JOB_NUMBER_TM_INIT:
			return {...state, loading: true, loaded: false, error: false};
		case GET_AVAILABLE_JOB_NUMBER_TM_SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
				error: false,
				data: payload,
			};
		case GET_AVAILABLE_JOB_NUMBER_TM_FAILURE:
			return {...state, loading: false, loaded: false, error: true};
		default:
			return state;
	}
}
const allJobsInitialState = {
	loading: false,
	loaded: false,
	error: false,
	data: {},
};

function allJobsReducer(state = allJobsInitialState, {type, payload}) {
	switch (type) {
		case GET_ALL_JOBS_DATA_INIT:
			return {...state, loading: true, loaded: false, error: false};
		case GET_ALL_JOBS_DATA_SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
				error: false,
				data: payload,
			};
		case GET_ALL_JOBS_DATA_FAILURE:
			return {...state, loading: false, loaded: false, error: true};
		case SET_JOB_STATUS_START:
			return {
				...state,
				loading: true
			};
		case SET_JOB_STATUS_SUCCESS:
			return {
				...state,
				loading: false,
				statusErrors: [],
			}
		case SET_JOB_STATUS_ERROR:
			return {
				...state,
				statusErrors: payload
			}
		case DELETE_JOB_START:
			return {
				...state,
				loading: true,
			};
		case DELETE_JOB:
			return {
				...state,
				loading: false,
				statusErrors: [],
			}
		case DELETE_JOB_ERROR:
			return {
				...state,
				statusErrors: payload
			}
		case SEARCH_JOB_START:
			return {
				...state,
			};
		case SEARCH_JOB_SUCCESS:
			return {
				...state,
				data: payload,
				loading: false,
			};
		case SEARCH_JOB_ERROR:
			return {
				...state,
				errors: payload,
			};
		default:
			return state;
	}
}

const quoteInitialState = {
	type: 1,
	description: '',
	customer_id: null,
	parent_quote_id: null,
	children_quotes: [],
	manager_id: null,
};

function quoteReducer(state = quoteInitialState, {type, payload}) {
	switch (type) {
		case SET_JOB_QUOTE_FIELDS: {
			const {name, value} = payload;
			return {...state, [name]: value};
		}
		case RESET_JOB_QUOTE_FIELDS: {
			return {
				...state,
				...quoteInitialState
			};
		}
		default:
			return state;
	}
}

const tmInitialState = {
	type: 2,
	name: '',
	description: '',
	customer_id: null,
	manager_id: null,
	job: null,
};

function tmReducer(state = tmInitialState, {type, payload}) {
	switch (type) {
		case SET_JOB_TM_FIELDS: {
			const {name, value} = payload;
			return {...state, [name]: value};
		}
		case RESET_TM_JOB_FIELDS: {
			return {
				...state,
				...tmInitialState,
			};
		}
		default:
			return state;
	}
}

const modalFieldsInitialState = {
	parentQuotes: [],
	childrenQuotes: [],
	jobs: [],
	managers: [],
	customers: [],
	tmJob: [],
};

function modalFieldsDataReducer(
	state = modalFieldsInitialState,
	{type, payload}
) {
	switch (type) {
		case GET_PARENT_QUOTES_LIST:
			return {...state, parentQuotes: payload};
		case GET_CHILDREN_QUOTES: {
			return {...state, childrenQuotes: payload};
		}
		case GET_CURRENT_JOB: {
			return {...state, jobs: payload};
		}
		case GET_MANAGERS_LIST: {
			return {...state, managers: payload};
		}
		case GET_CUSTOMERS_LIST_DATA: {
			return {...state, customers: payload};
		}
		case GET_TM_JOB_LIST: {
			return {...state, tmJob: payload};
		}
		case RESET_JOB_FIELDS: {
			return {...state, parentQuotes: [], childrenQuotes: [], managers: [], customers: []};
		}

		default:
			return state;
	}
}

const addNewJobInitialState = {
	sending: false,
	sent: false,
	error: false,
	errorPayload: null,
};

function addNewJobReducer(state = addNewJobInitialState, {type, payload}) {
	switch (type) {
		case PUT_NEW_JOB_INIT:
			return {
				...state,
				sending: true,
				sent: false,
				error: false,
				failure: null,
			};
		case PUT_NEW_JOB_SUCCESS:
			return {
				...state,
				sending: false,
				sent: true,
				error: false,
				failure: null,
			};

		case PUT_NEW_JOB_FAILURE:
			return {
				...state,
				sending: false,
				sent: false,
				error: true,
				failure: payload,
			};
		default:
			return state;
	}
}

const internalJobInitialState = {
	loading: false,
	loaded: false,
	error: false,
	data: {},
};

function internalJobReducer(state = internalJobInitialState, {type, payload}) {
	switch (type) {
		case GET_ALL_INTERNAL_JOB_INIT:
			return {...state, loading: true, loaded: false, error: false};
		case GET_ALL_INTERNAL_JOB_SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
				error: false,
				data: payload,
			};
		case GET_ALL_INTERNAL_JOB_FAILURE:
			return {...state, loading: false, loaded: false, error: true};
		case SEARCH_JOB_START:
			return {
				...state,
			};
		case SEARCH_JOB_SUCCESS:
			return {
				...state,
				data: payload,
				loading: false,
			};
		case SEARCH_JOB_ERROR:
			return {
				...state,
				errors: payload,
			};
		default:
			return state;
	}
}

//DETAILS
const allJobsDetailsState = {
	loading: false,
	loaded: false,
	error: false,
	data: {},
	item: {},
};

function allJobsDetailsReducer(state = allJobsDetailsState, {type, payload}) {
	switch (type) {
		case GET_JOB_ITEM_INIT:
			return {...state, loading: true, loaded: false, error: false};
		case GET_JOB_ITEM__SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
				error: false,
				item: payload,
			};
		case GET_JOB_ITEM__FAILURE:
			return {...state, loading: false, loaded: false, error: true};

		case GET_ALL_JOB_DETAILS_INIT:
			return {...state, loading: true, loaded: false, error: false};
		case GET_ALL_JOB_DETAILS_SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
				error: false,
				data: payload,
			};
		case GET_ALL_JOB_DETAILS_FAILURE:
			return {...state, loading: false, loaded: false, error: true};
		case PUT_NEW_JOB_DETAILS_INIT:
			return {
				...state,
				loading: true,
				loaded: false,
				error: false,
				failure: null,
			};
		case PUT_NEW_JOB_DETAILS_SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
				error: false,
				failure: null,
			};

		case PUT_NEW_JOB_DETAILS_FAILURE:
			return {
				...state,
				loading: false,
				loaded: false,
				error: true,
				failure: payload,
			};

		case DELETE_JOB_DETAILS_START:
			return {
				...state,
				loading: true,
			};
		case DELETE_JOB_DETAILS_SUCCESS:
			return {
				...state,
				loading: false,
				statusErrors: [],
			}
		case DELETE_JOB_DETAILS_ERROR:
			return {
				...state,
				statusErrors: payload
			}

		case SET_LABOUR_STATUS_START:
			return {
				...state,
				loading: true
			};
		case SET_LABOUR_STATUS_SUCCESS:
			return {
				...state,
				loading: false,
				statusErrors: [],
			}
		case SET_LABOUR_STATUS_ERROR:
			return {
				...state,
				statusErrors: payload
			}
		case SEARCH_ALL_JOBS_DETAILS_START:
			return {
				...state,
			};
		case SEARCH_ALL_JOBS_DETAILS_SUCCESS:
			return {
				...state,
				data: payload,
				loading: false,
			};
		case SEARCH_ALL_JOBS_DETAILS_ERROR:
			return {
				...state,
				errors: payload,
			};
		default:
			return state;
	}
}

const allJobStatisticState = {
	loading: false,
	loaded: false,
	error: false,
	data: {},
};

function allJobStatisticReducer(state = allJobStatisticState, {type, payload}) {
	switch (type) {
		case GET_JOB_STATISTIC_INIT:
			return {...state, loading: true, loaded: false, error: false};
		case GET_JOB_STATISTIC_SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
				error: false,
				data: payload,
			};
		case GET_JOB_STATISTIC_FAILURE:
			return {...state, loading: false, loaded: false, error: true};
		default:
			return state;
	}
}

const addLabourTypeInitialState = {
	tiers: [],
};

function addLabourTypeReducer(state = addLabourTypeInitialState, {type, payload}) {
	switch (type) {
		case SET_LABOUR_TYPE_FIELDS: {
			return {...state, tiers: payload.name};
		}
		case RESET_LABOUR_TYPE_FIELDS: {
			return {
				...state,
				...addLabourTypeInitialState,
			};
		}
		default:
			return state;
	}
}

const internalJobsDetailsState = {
	loading: false,
	loaded: false,
	error: false,
	data: {},
};

function internalJobsDetailsReducer(state = internalJobsDetailsState, {type, payload}) {
	switch (type) {
		case GET_INTERNAL_JOB_DETAILS_INIT:
			return {...state, loading: true, loaded: false, error: false};
		case GET_INTERNAL_JOB_DETAILS_SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
				error: false,
				data: payload,
			};
		case GET_INTERNAL_JOB_DETAILS_FAILURE:
			return {...state, loading: false, loaded: false, error: true};

		case SEARCH_INTERNAL_JOBS_DETAILS_START:
			return {
				...state,
			};
		case SEARCH_INTERNAL_JOBS_DETAILS_SUCCESS:
			return {
				...state,
				data: payload,
				loading: false,
			};
		case SEARCH_INTERNAL_JOBS_DETAILS_ERROR:
			return {
				...state,
				errors: payload,
			};
		default:
			return state;
	}
}

export default combineReducers({
	tabIndex: tabIndexReducer,
	modalType: modalTypeReducer,
	quoteModalData: quoteReducer,
	tmModalData: tmReducer,
	allJobsTableData: allJobsReducer,
	modalFieldsData: modalFieldsDataReducer,
	sendNewJobData: addNewJobReducer,
	initialJobs: internalJobReducer,
	allJobsDetailsData: allJobsDetailsReducer,
	allJobStatistic: allJobStatisticReducer,
	labourType: addLabourTypeReducer,
	internalJobDetailsData: internalJobsDetailsReducer,
	availableNumber: availableNumberReducer,
	availableNumberTM: availableNumberTMReducer,
});
