import {
  SET_ERROR,
  REMOVE_ERROR,
} from '../constantTypes';

const initialState = {
  isError: false,
  errorMessage: '',
};

const errorReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ERROR:
      return {
        ...state,
        isError: action.payload.isError,
        errorMessage: action.payload.isError ? action.payload.message : '',
      };
    case REMOVE_ERROR:
      return {
        ...state,
        isError: false,
        errorMessage: '',
      };
    default:
      return state
  }
};

export default errorReducer;

