import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './reducers/rootReducer';
import { createLogger } from 'redux-logger'

const store = createStore(
  rootReducer,
  // composeWithDevTools(applyMiddleware(thunk, createLogger())),
  composeWithDevTools(applyMiddleware(thunk)),

);

export default store;