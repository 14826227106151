import {
  FETCH_RFQ_SUCCESS,
  CHANGE_VENDOR_FIELD, SET_RFQ_DRAFT
} from '../constantTypes';
import * as R from 'ramda'

const initialState = {
  loading: false,
  isError: false,
  vendors: [],
  value: null,
  values: [],
  draft: []
};

const createRFQReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RFQ_SUCCESS:
      const formatValues = item => {
        const obj = {};
        obj.value = item['vendor_id']; //vendor_id
        obj.label = item['vendor_name'];
        return obj
      };
      return {
        ...state,
        loading: true,
        vendors: action.payload,
        values: R.map(formatValues, action.payload),
        value: R.nth(0, R.map(formatValues, action.payload))
      };
    case CHANGE_VENDOR_FIELD:
      const currentVendor = R.find(R.propEq('vendor_id', action.payload.id), state.vendors); // vendor_id
      const vendorPosition = R.indexOf(currentVendor, state.vendors);
      currentVendor[action.payload.field] = action.payload.data;
      return {
        ...state,
        vendors: R.update(vendorPosition, currentVendor, state.vendors ),
      };
    case SET_RFQ_DRAFT:
      return {
        ...state,
        draft: action.payload.data,
      };
    default:
      return state
  }
};

export default createRFQReducer;

