import {
  GET_RESOURCES_DATA_INIT,
  GET_RESOURCES_DATA_SUCCESS,
  GET_RESOURCES_DATA_FAILURE,

  PUT_NEW_RESOURCE_INIT,
  PUT_NEW_RESOURCE_SUCCESS,
  PUT_NEW_RESOURCE_FAILURE,

  CHANGE_TAB_INDEX_RESOURCES,

  GET_JOB_RESOURCES_ITEM_INIT,
  GET_JOB_RESOURCES_ITEM_SUCCESS,
  GET_JOB_RESOURCES_ITEM_FAILURE,

  ATTACH_PARTS_TO_RESOURCE_INIT,
  ATTACH_PARTS_TO_RESOURCE_SUCCESS,
  ATTACH_PARTS_TO_RESOURCE_FAILURE,

  UPDATE_RESOURCE_ALLOCATION_PARTS_COUNT_INIT,
  UPDATE_RESOURCE_ALLOCATION_PARTS_COUNT_SUCCESS,
  UPDATE_RESOURCE_ALLOCATION_PARTS_COUNT_FAILURE,

  GET_RESOURCE_ITEM_INIT,
  GET_RESOURCE_ITEM_SUCCESS,
  GET_RESOURCE_ITEM_FAILURE,
} from '../constantTypes';

import {combineReducers} from 'redux';

const ResourceItemState = {
  loading: false,
  loaded: false,
  error: false,
  data: {},
};

function resourceItemReducer(state = ResourceItemState, {type, payload}) {
  switch (type) {
    case GET_RESOURCE_ITEM_INIT:
      return {...state, loading: true, loaded: false, error: false};
    case GET_RESOURCE_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        data: payload,
      };
    case GET_RESOURCE_ITEM_FAILURE:
      return {...state, loading: false, loaded: false, error: payload};
    default:
      return state;
  }
}

const ResourcesState = {
  loading: false,
  loaded: false,
  error: false,
  data: {},
};

function resourcesReducer(state = ResourcesState, {type, payload}) {
	switch (type) {
		case GET_RESOURCES_DATA_INIT:
			return {...state, loading: true, loaded: false, error: false};
		case GET_RESOURCES_DATA_SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
				error: false,
				data: payload,
			};
		case GET_RESOURCES_DATA_FAILURE:
			return {...state, loading: false, loaded: false, error: true};

    case PUT_NEW_RESOURCE_INIT:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
        failure: null,
      };
    case PUT_NEW_RESOURCE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        failure: null,
      };

    case PUT_NEW_RESOURCE_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
        failure: payload,
      };

    case ATTACH_PARTS_TO_RESOURCE_INIT:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
        failure: null,
      };
    case ATTACH_PARTS_TO_RESOURCE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        failure: null,
      };

    case ATTACH_PARTS_TO_RESOURCE_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
        failure: payload,
      };

    case UPDATE_RESOURCE_ALLOCATION_PARTS_COUNT_INIT:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
        failure: null,
      };
    case UPDATE_RESOURCE_ALLOCATION_PARTS_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        failure: null,
      };

    case UPDATE_RESOURCE_ALLOCATION_PARTS_COUNT_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
        failure: payload,
      };
    default:
			return state;
	}
}

function tabIndexReducer(state = 0, {type, payload}) {
  switch (type) {
    case CHANGE_TAB_INDEX_RESOURCES: {
      return payload;
    }
    default:
      return state;
  }
}

const resourcesDetailsState = {
  loading: false,
  loaded: false,
  error: false,
  items: [],
};

function jobResourcesDetailsReducer(state = resourcesDetailsState, {type, payload}) {
  switch (type) {
    case GET_JOB_RESOURCES_ITEM_INIT:
      return {...state, loading: true, loaded: false, error: false};
    case GET_JOB_RESOURCES_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        items: payload.items,
      };
    case GET_JOB_RESOURCES_ITEM_FAILURE:
      return {...state, loading: false, loaded: false, error: true};

    default:
      return state;
  }
}

export default combineReducers({
  allResourcesData: resourcesReducer,
  tabIndex: tabIndexReducer,
  resourcesDetails: jobResourcesDetailsReducer,
  resourceItem: resourceItemReducer
});
