import {
  SHOW_MODAL_WINDOW,
  SET_CURRENT_WINDOW,
  SET_CURRENT_POPUP,
  CHANGE_TABLE_PARAMS
} from '../constantTypes'

const initialState = {
  isOpenModalWindow: false,
  currentWindow: '',
  currentPopup: '',
  params: {
    page: 1,
    sort: '',
    order: 'desc'
  }
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MODAL_WINDOW:
      return {
        ...state,
        isOpenModalWindow: action.payload,
      };
    case SET_CURRENT_WINDOW:
      return {
        ...state,
        currentWindow: action.payload,
      };
    case SET_CURRENT_POPUP:
      return {
        ...state,
        currentPopup: action.payload,
      };
    case CHANGE_TABLE_PARAMS:
      return {
        ...state,
        params: action.payload
      };
    default:
      return state
  }
};

export default appReducer;
