export const FETCH_DATA_ERROR = 'FETCH_DATA_ERROR';
export const FETCH_DATA_START = 'FETCH_DATA_START';
export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS';

export const FETCH_STOCK_LIST_START = 'FETCH_STOCK_LIST_START';
export const FETCH_STOCK_LIST_SUCCESS = 'FETCH_STOCK_LIST_SUCCESS';
export const FETCH_STOCK_LIST_ERROR = 'FETCH_STOCK_LIST_ERROR';
export const GET_FULL_STOCK_LIST_DATA_START = 'GET_FULL_STOCK_LIST_DATA_START';
export const GET_FULL_STOCK_LIST_DATA_SUCCESS =
    'GET_FULL_STOCK_LIST_DATA_SUCCESS';
export const GET_FULL_STOCK_LIST_DATA_ERROR = 'GET_FULL_STOCK_LIST_DATA_ERROR';

export const FETCH_PURCHASE_VENDOR_LIST_START =
    'FETCH_PURCHASE_VENDOR_LIST_START';
export const FETCH_PURCHASE_VENDOR_LIST_SUCCESS =
    'FETCH_PURCHASE_VENDOR_LIST_SUCCESS';
export const FETCH_PURCHASE_VENDOR_LIST_ERROR =
    'FETCH_PURCHASE_VENDOR_LIST_ERROR';

export const CREATE_PURCHASE_NEW_VENDOR_INIT = 'CREATE_PURCHASE_NEW_VENDOR_INIT';
export const CREATE_PURCHASE_NEW_VENDOR_SUCCESS = 'CREATE_PURCHASE_NEW_VENDOR_SUCCESS';
export const CREATE_PURCHASE_NEW_VENDOR_ERROR = 'CREATE_PURCHASE_NEW_VENDOR_ERROR';

export const FETCH_DETAILS_START = 'FETCH_DETAILS_START';
export const FETCH_DETAILS_SUCCESS = 'FETCH_DETAILS_SUCCESS';
export const FETCH_DETAILS_ERROR = 'FETCH_DETAILS_ERROR';

export const FETCH_COUNTRIES_START = 'FETCH_COUNTRIES_START';
export const FETCH_COUNTRIES_SUCCESS = 'FETCH_COUNTRIES_SUCCESS';
export const FETCH_COUNTRIES_ERROR = 'FETCH_COUNTRIES_ERROR';

export const FETCH_TAXES_START = 'FETCH_TAXES_START';
export const FETCH_TAXES_SUCCESS = 'FETCH_TAXES_SUCCESS';
export const FETCH_TAXES_ERROR = 'FETCH_TAXES_ERROR';

export const FETCH_CURRENCIES_START = 'FETCH_CURRENCIES_START';
export const FETCH_CURRENCIES_SUCCESS = 'FETCH_CURRENCIES_SUCCESS';
export const FETCH_CURRENCIES_ERROR = 'FETCH_CURRENCIES_ERROR';

export const FETCH_RFQ_START = 'FETCH_RFQ_START';
export const FETCH_RFQ_SUCCESS = 'FETCH_RFQ_SUCCESS';
export const FETCH_RFQ_ERROR = 'FETCH_RFQ_ERROR';

export const FETCH_AUTH_START = 'FETCH_AUTH_START';
export const FETCH_AUTH_SUCCESS = 'FETCH_AUTH_SUCCESS';
export const FETCH_AUTH_ERROR = 'FETCH_AUTH_ERROR';
export const LOGOUT_AUTH = 'LOGOUT_AUTH';
export const CHANGE_VENDOR_FIELD = 'CHANGE_VENDOR_FIELD';
export const SET_RFQ_DRAFT = 'SET_RFQ_DRAFT';

export const FETCH_PARTS_APPROVAL_START = 'FETCH_PARTS_APPROVAL_START';
export const FETCH_PARTS_APPROVAL_SUCCESS = 'FETCH_PARTS_APPROVAL_SUCCESS';
export const FETCH_PARTS_APPROVAL_ERROR = 'FETCH_PARTS_APPROVAL_ERROR';
export const SET_PARTS_APPROVE_IDS = 'SET_PARTS_APPROVE_IDS';

export const FETCH_PRODUCT_TYPES_START = 'FETCH_PRODUCT_TYPES_START';
export const FETCH_PRODUCT_TYPES_SUCCESS = 'FETCH_PRODUCT_TYPES_SUCCESS';
export const FETCH_PRODUCT_TYPES_ERROR = 'FETCH_PRODUCT_TYPES_ERROR';

export const ADD_NEW_VENDOR = 'ADD_NEW_VENDOR';
export const DELETE_NEW_VENDOR = 'DELETE_NEW_VENDOR';
export const CREATE_VENDOR_LIST = 'CREATE_VENDOR_LIST';

export const SELECT_VENDOR = 'SELECT_VENDOR';
export const CHANGE_VENDOR_QTY = 'CHANGE_VENDOR_QTY';
export const CHANGE_VENDOR_NAME = 'CHANGE_VENDOR_NAME';

export const SHOW_MODAL_WINDOW = 'SHOW_MODAL_WINDOW';
export const SET_CURRENT_WINDOW = 'SET_CURRENT_WINDOW';
export const SET_CURRENT_POPUP = 'SET_CURRENT_POPUP';

export const FETCH_TAX_LIST_START = 'FETCH_TAX_LIST_START';
export const FETCH_TAX_LIST_SUCCESS = 'FETCH_TAX_LIST_SUCCESS';
export const FETCH_TAX_LIST_ERROR = 'FETCH_TAX_LIST_ERROR';

export const FETCH_INFO_START = 'FETCH_INFO_START';
export const FETCH_INFO_STARTFETCH_INFO_SUCCESS =
    'FETCH_INFO_STARTFETCH_INFO_SUCCESS';
export const FETCH_INFOERROR_ERROR = 'FETCH_INFOERROR_ERROR';

export const CHANGE_TABLE_PARAMS = 'CHANGE_TABLE_PARAMS';

export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

export const FETCH_DETAILSLIST_START = 'FETCH_DETAILSLIST_START';
export const FETCH_DETAILSLIST_SUCCESS = 'FETCH_DETAILSLIST_SUCCESS';
export const FETCH_DETAILSLIST_ERROR = 'FETCH_DETAILSLIST_ERROR';
export const CREATE_NEW_CONTACT = 'CREATE_NEW_CONTACT';
export const UPDATE_NEW_CONTACT = 'UPDATE_NEW_CONTACT';
export const UPDATE_EXIST_CONTACT = 'UPDATE_EXIST_CONTACT';
export const DELETE_EXIST_CONTACT = 'DELETE_EXIST_CONTACT';

export const FETCH_QUOTES_LIST_START = 'FETCH_QUOTES_LIST_START';
export const FETCH_QUOTES_LIST_SUCCESS = 'FETCH_QUOTES_LIST_SUCCESS';
export const FETCH_QUOTES_LIST_ERROR = 'FETCH_QUOTES_LIST_ERROR';
export const DELETE_QUOTE = 'DELETE_QUOTE';
export const DELETE_QUOTE_ERROR = 'DELETE_QUOTE_ERROR';
export const GET_QUOTE_DATA_NUMBER = 'GET_QUOTE_DATA_NUMBER';
export const GET_QUOTE_DATA_NUMBER_ERROR = 'GET_QUOTE_DATA_NUMBER_ERROR';
export const POST_QUOTE = ' POST_QUOTE';
export const POST_QUOTE_ERROR = 'POST_QUOTE_ERROR';
export const CLONE_QUOTE_START = 'CLONE_QUOTE_START';
export const CLONE_QUOTE_SUCCESS = 'CLONE_QUOTE_SUCCESS';
export const CLONE_QUOTE_ERROR = 'CLONE_QUOTE_ERROR';
export const PARENT_QUOTE_STATUS_START = 'PARENT_QUOTE_STATUS_START';
export const PARENT_QUOTE_STATUS_ERROR = 'PARENT_QUOTE_STATUS_ERROR';
export const PARENT_QUOTE_STATUS_SUCCESS = 'PARENT_QUOTE_STATUS_SUCCESS';

export const FETCH_CHILD_QUOTES_LIST_START = 'FETCH_CHILD_QUOTES_LIST_START';
export const FETCH_CHILD_QUOTES_LIST_SUCCESS =
    'FETCH_CHILD_QUOTES_LIST_SUCCESS';
export const FETCH_CHILD_QUOTES_LIST_ERROR = 'FETCH_CHILD_QUOTES_LIST_ERROR';
export const DELETE_CHILD_QUOTE = 'DELETE_CHILD_QUOTE';
export const DELETE_CHILD_QUOTE_ERROR = 'DELETE_CHILD_QUOTE_ERROR';
export const CLONE_CHILD_QUOTE_START = 'CLONE_CHILD_QUOTE_START';
export const CLONE_CHILD_QUOTE_SUCCESS = 'CLONE_CHILD_QUOTE_SUCCESS';
export const CLONE_CHILD_QUOTE_ERROR = 'CLONE_CHILD_QUOTE_ERROR';
export const CHILD_QUOTE_REVISION_START = 'CHILD_QUOTE_REVISION_START';
export const CHILD_QUOTE_REVISION_SUCCESS = 'CHILD_QUOTE_REVISION_SUCCESS';
export const CHILD_QUOTE_REVISION_ERROR = 'CHILD_QUOTE_REVISION_ERROR';
export const CHILD_QUOTE_STATUS_START = 'CHILD_QUOTE_STATUS_START';
export const CHILD_QUOTE_STATUS_SUCCESS = 'CHILD_QUOTE_STATUS_SUCCESS';
export const CHILD_QUOTE_STATUS_ERROR = 'CHILD_QUOTE_STATUS_ERROR';

// export const EDIT_VENDORS_PART_START = 'EDIT_VENDORS_PART_START';
// export const EDIT_VENDORS_PART_SUCCESS = 'EDIT_VENDORS_PART_SUCCESS';
// export const EDIT_VENDORS_PART_ERR = 'EDIT_VENDORS_PART_ERR';

export const GET_CUSTOMERS_LIST = 'GET_CUSTOMERS_LIST';
export const GET_CUSTOMERS_LIST_ERROR = 'GET_CUSTOMERS_LIST_ERROR';
export const GET_COMPANIES_LIST = 'GET_COMPANIES_LIST';
export const GET_COMPANIES_LIST_ERROR = 'GET_COMPANIES_LIST_ERROR';

export const SET_CHILD_QUOTE_ITEM = 'SET_CHILD_QUOTE_ITEM';
export const POST_CHILD_QUOTE_ITEM = 'POST_CHILD_QUOTE_ITEM';
export const SET_CHILD_QUOTE_ITEM_ERROR = 'SET_CHILD_QUOTE_ITEM_ERROR';
export const EDIT_CHILD_QUOTE_ERROR = 'EDIT_CHILD_QUOTE_ERROR';
export const EDIT_CHILD_QUOTE = 'EDIT_CHILD_QUOTE';
export const EDIT_CHILD_QUOTE_ITEM = 'EDIT_CHILD_QUOTE_ITEM';
export const EDIT_CHILD_QUOTE_ITEM_ERROR = 'EDIT_CHILD_QUOTE_ITEM_ERROR';
export const EDIT_CHILD_QUOTE_ITEM_START = 'EDIT_CHILD_QUOTE_ITEM_START';

export const ADD_NEW_TIER_START = 'ADD_NEW_TIER_START';
export const ADD_NEW_TIER_SUCCESS = 'ADD_NEW_TIER_SUCCESS';
export const ADD_NEW_TIER_ERROR = 'ADD_NEW_TIER_ERROR';

export const GET_TIER_LIST_START = 'GET_TIER_LIST_START';
export const GET_TIER_LIST_SUCCESS = 'GET_TIER_LIST_SUCCESS';
export const GET_TIERLIST_ERROR = 'GET_TIERLIST_ERROR';
export const GET_TIER_MANAGMENT_LIST_START = 'GET_TIER_MANAGMENT_LIST_START';
export const GET_TIER_MANAGMENT_LIST_SUCCES = 'GET_TIER_MANAGMENT_LIST_SUCCES';
export const GET_TIER_MANAGMENT_LIST_ERROR = 'GET_TIER_MANAGMENT_LIST_ERROR';
export const DELETE_TIER_MANAGMENT_ITEM_START =
    'DELETE_TIER_MANAGMENT_ITEM_START';
export const DELETE_TIER_MANAGMENT_ITEM_SUCCES =
    'DELETE_TIER_MANAGMENT_ITEM_SUCCES';
export const DELETE_TIER_MANAGMENT_ITEM_ERROR =
    'DELETE_TIER_MANAGMENT_ITEM_ERROR';
export const PUT_NEW_TIER_START = 'PUT_NEW_TIER_START';
export const PUT_NEW_TIER_ERROR = 'PUT_NEW_TIER_ERROR';
export const PUT_NEW_TIER_SUCCESS = 'PUT_NEW_TIER_SUCCESS';
export const GET_TIER_MANAGMENT_ALL_DATA_LIST_START =
    'GET_TIER_MANAGMENT_ALL_DATA_LIST_START';
export const GET_TIER_MANAGMENT_ALL_DATA_LIST_SUCCESS =
    'GET_TIER_MANAGMENT_ALL_DATA_LIST_SUCCESS';
export const GET_TIER_MANAGMENT_ALL_DATA_LIST_ERROR =
    'GET_TIER_MANAGMENT_ALL_DATA_LIST_ERROR';

export const GET_COST_TYPE_LIST_START = 'GET_COST_TYPE_LIST_START';
export const GET_COST_TYPE_LIST_SUCCESS = 'GET_COST_TYPE_LIST_SUCCESS';
export const GET_COST_TYPE_LIST_ERROR = 'GET_COST_TYPE_LIST_ERROR';

export const ADD_NEW_LABOUR_TYPE_START = 'ADD_NEW_LABOUR_TYPE_START';
export const ADD_NEW_LABOUR_TYPE_SUCCESS = 'ADD_NEW_LABOUR_TYPE_SUCCESS';
export const ADD_NEW_LABOUR_TYPE_ERROR = 'ADD_NEW_LABOUR_TYPE_ERROR';
export const GET_LABOUR_TYPE_LIST_START = 'GET_LABOUR_TYPE_LIST_START';
export const GET_LABOUR_TYPE_LIST_SUCCES = 'GET_LABOUR_TYPE_LIST_SUCCES';
export const GET_LABOUR_TYPE_LIST_ERROR = 'GET_LABOUR_TYPE_LIST_ERROR';
export const LABOUR_TYPE_CALCULATIONS_START = 'LABOUR_TYPE_CALCULATIONS_START';
export const LABOUR_TYPE_CALCULATIONS_SUCCES =
    'LABOUR_TYPE_CALCULATIONS_SUCCESS';
export const LABOUR_TYPE_CALCULATIONS_ERROR = 'LABOUR_TYPE_CALCULATIONS_ERROR';
export const GET_QUOTE_STATISTIC_START = 'GET_QUOTE_STATISTIC_START';
export const GET_QUOTE_STATISTIC_SUCCESS = 'GET_QUOTE_STATISTIC_SUCCESS';
export const GET_QUOTE_STATISTIC_ERROR = 'GET_QUOTESTATISTIC_ERROR';
export const EDIT_LABOUR_TYPE_LIST_START = 'EDIT_LABOUR_TYPE_LIST_START';
export const EDIT_LABOUR_TYPE_LIST_SUCCES = 'EDIT_LABOUR_TYPE_LIST_SUCCES';
export const SYNC_LABOUR_TYPE_DATA = 'SYNC_LABOUR_TYPE_DATA';
export const EDIT_LABOUR_TYPE_LIST_ERROR = 'EDIT_LABOUR_TYPE_LIST_ERROR';
export const ADD_LABOUR_TYPE_FROM_TIER_START =
    'ADD_LABOUR_TYPE_FROM_TIER_START';
export const ADD_LABOUR_TYPE_FROM_TIER_SUCCESS =
    'ADD_LABOUR_TYPE_FROM_TIER_SUCCESS';
export const ADD_LABOUR_TYPE_FROM_TIER_ERROR =
    'ADD_LABOUR_TYPE_FROM_TIER_ERROR';

export const GET_MATERIAL_DETAILS_LIST_START =
    'GET_MATERIAL_DETAILS_LIST_START';
export const GET_MATERIAL_DETAILS_LIST_SUCCESS =
    'GET_MATERIAL_DETAILS_LIST_SUCCESS';
export const GET_MATERIAL_DETAILS_LIST_ERROR =
    'GET_MATERIAL_DETAILS_LIST_ERROR';
export const POST_MATERIAL_DETAILS_DATA_START =
    'POST_MATERIAL_DETAILS_DATA_START';
export const POST_MATERIAL_DETAILS_DATA_SUCCESS =
    'POST_MATERIAL_DETAILS_DATA_SUCCESS';
export const POST_MATERIAL_DETAILS_DATA_ERROR =
    'POST_MATERIAL_DETAILS_DATA_ERROR';
export const DELETE_MATERIAL_DETAILS_ITEM_START =
    'DELETE_MATERIAL_DETAILS_ITEM_START';
export const DELETE_MATERIAL_DETAILS_ITEM_SUCCESS =
    'DELETE_MATERIAL_DETAILS_ITEM_SUCCESS';
export const DELETE_MATERIAL_DETAILS_ITEM_ERROR =
    'DELETE_MATERIAL_DETAILS_ITEM_ERROR';
export const GET_MATERIAL_DETAILS_CALCULATIONS_START =
    'GET_MATERIAL_DETAILS_CALCULATIONS_START';
export const GET_MATERIAL_DETAILS_CALCULATIONS_SUCCESS =
    'GET_MATERIAL_DETAILS_CALCULATIONS_SUCCESS';
export const GET_MATERIAL_DETAILS_CALCULATIONS_ERROR =
    'GET_MATERIAL_DETAILS_CALCULATIONS_ERROR';
export const POST_MATERIAL_DETAILS_FROM_STOCK_LIST_START =
    'POST_MATERIAL_DETAILS_FROM_STOCK_LIST_START';
export const POST_MATERIAL_DETAILS_FROM_STOCK_LIST_SUCCESS =
    'POST_MATERIAL_DETAILS_FROM_STOCK_LIST_SUCCESS';
export const POST_MATERIAL_DETAILS_FROM_STOCK_LIST_ERROR =
    'POST_MATERIAL_DETAILS_FROM_STOCK_LIST_ERROR';

export const GET_TM_JOBS_START = 'GET_TM_JOBS_START';
export const GET_TM_JOBS_SUCCESS = 'GET_TM_JOBS_SUCCESS';
export const GET_TM_JOBS_ERROR = 'GET_TM_JOBS_ERROR';

export const GET_RQ_JOBS_START = 'GET_RQ_JOBS_START';
export const GET_RQ_JOBS_SUCCESS = 'GET_RQ_JOBS_SUCCESS';
export const GET_RQ_JOBS_ERROR = 'GET_RQ_JOBS_ERROR';

export const GET_INTERNAL_JOBS_START = 'GET_INTERNAL_JOBS_START';
export const GET_INTERNAL_JOBS_SUCCESS = 'GET_INTERNAL_JOBS_SUCCESS';
export const GET_INTERNAL_JOBS_ERROR = 'GET_INTERNAL_JOBS_ERROR';

export const SEARCH_VENDORS_START = 'SEARCH_VENDORS_START';
export const SEARCH_VENDORS_SUCCESS = 'SEARCH_VENDORS_SUCCESS';
export const SEARCH_VENDORS_ERROR = 'SEARCH_VENDORS_ERROR';

export const SEARCH_PARENT_QUOTE_START = 'SEARCH_PARENT_QUOTE_START';
export const SEARCH_PARENT_QUOTE_SUCCESS = 'SEARCH_PARENT_QUOTE_SUCCESS';
export const SEARCH_PARENT_QUOTE_ERROR = 'SEARCH_PARENT_QUOTE_ERROR';

export const SEARCH_CHILD_QUOTE_START = 'SEARCH_CHILD_QUOTE_START';
export const SEARCH_CHILD_QUOTE_SUCCESS = 'SEARCH_CHILD_QUOTE_SUCCESS';
export const SEARCH_CHILD_QUOTE_ERROR = 'SEARCH_CHILD_QUOTE_ERROR';

export const SEARCH_LABOUR_TYPE_START = 'SEARCH_LABOUR_TYPE_START';
export const SEARCH_LABOUR_TYPE_SUCCESS = 'SEARCH_LABOUR_TYPE_SUCCESS';
export const SEARCH_LABOUR_TYPE_ERROR = 'SEARCH_LABOUR_TYPE_ERROR';

export const SEARCH_PO_LIST_DATA_START = 'SEARCH_PO_LIST_DATA_START';
export const SEARCH_PO_LIST_DATA_SUCCESS = 'SEARCH_PO_LIST_DATA_SUCCESS';
export const SEARCH_PO_LIST_DATA_ERROR = 'SEARCH_PO_LIST_DATA_ERROR';

export const GET_PO_STATUS_START = 'GET_PO_STATUS_START';
export const GET_PO_STATUS_SUCCESS = 'GET_PO_STATUS_SUCCESS';
export const GET_PO_STATUS_ERROR = 'GET_PO_STATUS_ERROR';

export const SEARCH_RFQ_START = 'SEARCH_RFQ_START';
export const SEARCH_RFQ_SUCESS = 'SEARCH_RFQ_SUCESS';
export const SEARCH_RFQ_ERROR = 'SEARCH_RFQ_ERROR';

export const SEARCH_CURRENCY_START = 'SEARCH_CURRENCY_START';
export const SEARCH_CURRENCY_SUCESS = 'SEARCH_CURRENCY_SUCESS';
export const SEARCH_CURRENCY_ERROR = 'SEARCH_CURRENCY_ERROR';

export const SEARCH_TAXES_START = 'SEARCH_TAXES_START';
export const SEARCH_TAXES_SUCCESS = 'SEARCH_TAXES_SUCCESS';
export const SEARCH_TAXES_ERROR = 'SEARCH_TAXES_ERROR';

export const SEARCH_STOCK_LIST_START = 'SEARCH_STOCK_LIST_START';
export const SEARCH_STOCK_LIST_SUCCESS = 'SEARCH_STOCK_LIST_SUCCESS';
export const SEARCH_STOCK_LIST_ERROR = 'SEARCH_STOCK_LIST_ERROR';

export const GET_CLIENTS_LIST_START = 'GET_CLIENTS_LIST_START';
export const GET_CLIENTS_LIST_SUCCESS = 'GET_CLIENTS_LIST_SUCCESS';
export const GET_CLIENTS_LIST_ERROR = 'GET_CLIENTS_LIST_ERROR';

export const FETCH_DETAILSLIST_CLIENTS_START =
    'FETCH_DETAILSLIST_CLIENTS_START';
export const FETCH_DETAILSLIST_CLIENTS_SUCCESS =
    'FETCH_DETAILSLIST_CLIENTS_SUCCESS';
export const FETCH_DETAILSLIST_CLIENTS_ERROR =
    'FETCH_DETAILSLIST_CLIENTS_ERROR';
export const CREATE_NEW_CLIENT_CONTACT = 'CREATE_NEW_CLIENT_CONTACT';
export const UPDATE_NEW_CLIENT_CONTACT = 'UPDATE_NEW_CLIENT_CONTACT';
export const UPDATE_EXIST_CLIENT_CONTACT = 'UPDATE_EXIST_CLIENT_CONTACT';
export const DELETE_EXIST_CLIENT_CONTACT = 'DELETE_EXIST_CLIENT_CONTACT';

// JOBS MODULE
export const GET_ALL_JOBS_DATA_INIT = 'GET_ALL_JOBS_DATA_INIT';
export const GET_ALL_JOBS_DATA_SUCCESS = 'GET_ALL_JOBS_DATA_SUCCESS';
export const GET_ALL_JOBS_DATA_FAILURE = 'GET_ALL_JOBS_DATA_FAILURE';

export const GET_PARENT_QUOTES_LIST = 'GET_PARENT_QUOTES_LIST';
export const GET_CHILDREN_QUOTES = 'GET_CHILDREN_QUOTES';
export const GET_CURRENT_JOB = 'GET_CURRENT_JOB';
export const GET_MANAGERS_LIST = 'GET_MANAGERS_LIST';
export const GET_CUSTOMERS_LIST_DATA = 'GET_CUSTOMERS_LIST_DATA';
export const RESET_JOB_FIELDS = 'RESET_JOB_FIELDS';
export const RESET_JOB_QUOTE_FIELDS = 'RESET_JOB_QUOTE_FIELDS';
export const RESET_TM_JOB_FIELDS = 'RESET_TM_JOB_FIELDS';
export const GET_TM_JOB_LIST = 'GET_TM_JOB_LIST';

export const CHANGE_TAB_INDEX = 'CHANGE_TAB_INDEX';
export const CHANGE_MODAL_TYPE = 'CHANGE_MODAL_TYPE';
export const SET_JOB_QUOTE_FIELDS = 'SET_JOB_QUOTE_FIELDS';
export const SET_JOB_TM_FIELDS = 'SET_JOB_TM_FIELDS';

export const DELETE_JOB_START = 'DELETE_JOB_START';
export const DELETE_JOB_ERROR = 'DELETE_JOB_ERROR';
export const DELETE_JOB = 'DELETE_JOB';
export const SET_JOB_STATUS_START = 'SET_JOB_STATUS_START';
export const SET_JOB_STATUS_SUCCESS = 'SET_JOB_STATUS_SUCCESS';
export const SET_JOB_STATUS_ERROR = 'SET_JOB_STATUS_ERROR';

export const GET_ALL_INTERNAL_JOB_INIT = 'GET_ALL_INTERNAL_JOB_INIT';
export const GET_ALL_INTERNAL_JOB_SUCCESS = 'GET_ALL_INTERNAL_JOB_SUCCESS';
export const GET_ALL_INTERNAL_JOB_FAILURE = 'GET_ALL_INTERNAL_JOB_FAILURE';

export const PUT_NEW_JOB_INIT = 'PUT_NEW_JOB_INIT';
export const PUT_NEW_JOB_SUCCESS = 'PUT_NEW_JOB_SUCCESS';
export const PUT_NEW_JOB_FAILURE = 'PUT_NEW_JOB_FAILURE';

export const SEARCH_JOB_START = 'SEARCH_JOB_START';
export const SEARCH_JOB_SUCCESS = 'SEARCH_JOB_SUCCESS';
export const SEARCH_JOB_ERROR = 'SEARCH_JOB_ERROR';
// JOBS DETALES MODULE
export const GET_ALL_JOB_DETAILS_INIT = 'GET_ALL_JOB_DETAILS_INIT';
export const GET_ALL_JOB_DETAILS_SUCCESS = 'GET_ALL_JOB_DETAILS_SUCCESS';
export const GET_ALL_JOB_DETAILS_FAILURE = 'GET_ALL_JOB_DETAILS_FAILURE';

export const PUT_NEW_JOB_DETAILS_INIT = 'PUT_NEW_JOB_DETAIL_INIT';
export const PUT_NEW_JOB_DETAILS_SUCCESS = 'PUT_NEW_JOB_DETAIL_SUCCESS';
export const PUT_NEW_JOB_DETAILS_FAILURE = 'PUT_NEW_JOB_DETAIL_FAILURE';

export const GET_JOB_STATISTIC_INIT = 'GET__JOB_STATISTIC_INIT';
export const GET_JOB_STATISTIC_SUCCESS = 'GET__JOB_STATISTIC_SUCCESS';
export const GET_JOB_STATISTIC_FAILURE = 'GET__JOB_STATISTIC_FAILURE';
export const SET_LABOUR_TYPE_FIELDS = 'SET_LABOUR_TYPE_FIELDS';
export const RESET_LABOUR_TYPE_FIELDS = 'RESET_LABOUR_TYPE_FIELDS';

export const DELETE_JOB_DETAILS_START = 'DELETE_JOB_DETAILS_START';
export const DELETE_JOB_DETAILS_SUCCESS = 'DELETE_JOB_DETAILS_SUCCESS';
export const DELETE_JOB_DETAILS_ERROR = 'DELETE_JOB_DETAILS_ERROR';

export const SET_LABOUR_STATUS_START = 'SET_LABOUR_STATUS_START';
export const SET_LABOUR_STATUS_SUCCESS = 'SET_LABOUR_STATUS_SUCCESS';
export const SET_LABOUR_STATUS_ERROR = 'SET_LABOUR_STATUS_ERROR';

export const GET_INTERNAL_JOB_DETAILS_INIT = 'GET_INTERNAL_JOB_DETAILS_INIT';
export const GET_INTERNAL_JOB_DETAILS_SUCCESS = 'GET_INTERNAL_JOB_DETAILS_SUCCESS';
export const GET_INTERNAL_JOB_DETAILS_FAILURE = 'GET_INTERNAL_JOB_DETAILS_FAILURE';

export const SEARCH_ALL_JOBS_DETAILS_START = 'SEARCH_ALL_JOBS_DETAILS_START';
export const SEARCH_ALL_JOBS_DETAILS_SUCCESS = 'SEARCH_ALL_JOBS_DETAILS_SUCCESS';
export const SEARCH_ALL_JOBS_DETAILS_ERROR = 'SEARCH_ALL_JOBS_DETAILS_ERROR';
export const SEARCH_INTERNAL_JOBS_DETAILS_START = 'SEARCH_INTERNAL_JOBS_DETAILS_START';
export const SEARCH_INTERNAL_JOBS_DETAILS_SUCCESS = 'SEARCH_INTERNAL_JOBS_DETAILS_SUCCESS';
export const SEARCH_INTERNAL_JOBS_DETAILS_ERROR = 'SEARCH_INTERNAL_JOBS_DETAILS_ERROR';

export const GET_JOB_ITEM_INIT = 'GET_JOB_ITEM_INIT';
export const GET_JOB_ITEM__SUCCESS = 'GET_JOB_ITEM__SUCCESS';
export const GET_JOB_ITEM__FAILURE = 'GET_JOB_ITEM__FAILURE';

export const GET_AVAILABLE_JOB_NUMBER_INIT = 'GET_AVAILABLE_JOB_NUMBER_INIT';
export const GET_AVAILABLE_JOB_NUMBER_SUCCESS = 'GET_AVAILABLE_JOB_NUMBER_SUCCESS';
export const GET_AVAILABLE_JOB_NUMBER_FAILURE = 'GET_AVAILABLE_JOB_NUMBER_FAILURE';
export const GET_AVAILABLE_JOB_NUMBER_TM_INIT = 'GET_AVAILABLE_JOB_NUMBER_TM_INIT';
export const GET_AVAILABLE_JOB_NUMBER_TM_SUCCESS = 'GET_AVAILABLE_JOB_NUMBER_TM_SUCCESS';
export const GET_AVAILABLE_JOB_NUMBER_TM_FAILURE = 'GET_AVAILABLE_JOB_NUMBER_TM_FAILURE';

//Calendar
export const GET_JOB_LIST_INIT = 'GET_JOB_LIST_INIT';
export const GET_JOB_LIST_SUCCESS = 'GET_JOB_LIST_SUCCESS';
export const GET_JOB_LIST_FAILURE = 'GET_JOB_LIST_FAILURE';

export const GET_LABOUR_TYPES_INIT = 'GET_LABOUR_TYPES_INIT';
export const GET_LABOUR_TYPES_SUCCESS = 'GET_LABOUR_TYPES_SUCCESS';
export const GET_LABOUR_TYPES_FAILURE = 'GET_LABOUR_TYPES_FAILURE';

export const GET_CUSTOMER_INIT = 'GET_CUSTOMER_INIT';
export const GET_CUSTOMER_SUCCESS = 'GET_CUSTOMER_SUCCESS';
export const GET_CUSTOMER_FAILURE = 'GET_CUSTOMER_FAILURE';

export const ADD_NEW_CALENDAR_EVENT_INIT = 'ADD_NEW_CALENDAR_EVENT_INIT';
export const ADD_NEW_CALENDAR_EVENT_SUCCESS = 'ADD_NEW_CALENDAR_EVENT_SUCCESS';
export const ADD_NEW_CALENDAR_EVENT_FAILURE = 'ADD_NEW_CALENDAR_EVENT_FAILURE';

export const RESET_MODAL_FIELDS = 'RESET_MODAL_FIELDS';

export const GET_EMPLOYEES_INIT = 'GET_EMPLOYEES_INIT';
export const GET_EMPLOYEES_SUCCESS = 'GET_EMPLOYEES_SUCCESS';
export const GET_EMPLOYEES_FAILURE = 'GET_EMPLOYEES_FAILURE';

export const SET_CURRENT_DAY = 'SET_TODAY';

export const GET_RESOURCES_DATA_INIT = 'GET_RESOURCES_DATA_INIT';
export const GET_RESOURCES_DATA_SUCCESS = 'GET_RESOURCES_DATA_SUCCESS';
export const GET_RESOURCES_DATA_FAILURE = 'GET_RESOURCES_DATA_FAILURE';

export const PUT_NEW_RESOURCE_INIT = 'PUT_NEW_RESOURCE_INIT';
export const PUT_NEW_RESOURCE_SUCCESS = 'PUT_NEW_RESOURCE_SUCCESS';
export const PUT_NEW_RESOURCE_FAILURE = 'PUT_NEW_RESOURCE_FAILURE';

export const CHANGE_TAB_INDEX_RESOURCES = 'CHANGE_TAB_INDEX_RESOURCES';

export const GET_JOB_RESOURCES_ITEM_INIT = 'GET_JOB_RESOURCES_ITEM_INIT';
export const GET_JOB_RESOURCES_ITEM_SUCCESS = 'GET_JOB_RESOURCES_ITEM_SUCCESS';
export const GET_JOB_RESOURCES_ITEM_FAILURE = 'GET_JOB_RESOURCES_ITEM_FAILURE';

export const ATTACH_PARTS_TO_RESOURCE_INIT = 'ATTACH_PARTS_TO_RESOURCE_INIT';
export const ATTACH_PARTS_TO_RESOURCE_SUCCESS = 'ATTACH_PARTS_TO_RESOURCE_SUCCESS';
export const ATTACH_PARTS_TO_RESOURCE_FAILURE = 'ATTACH_PARTS_TO_RESOURCE_FAILURE';

export const UPDATE_RESOURCE_ALLOCATION_PARTS_COUNT_INIT = 'UPDATE_RESOURCE_ALLOCATION_PARTS_COUNT_INIT';
export const UPDATE_RESOURCE_ALLOCATION_PARTS_COUNT_SUCCESS = 'UPDATE_RESOURCE_ALLOCATION_PARTS_COUNT_SUCCESS';
export const UPDATE_RESOURCE_ALLOCATION_PARTS_COUNT_FAILURE = 'UPDATE_RESOURCE_ALLOCATION_PARTS_COUNT_FAILURE';

export const GET_RESOURCE_ITEM_INIT = 'GET_RESOURCE_ITEM_INIT';
export const GET_RESOURCE_ITEM_SUCCESS = 'GET_RESOURCE_ITEM_SUCCESS';
export const GET_RESOURCE_ITEM_FAILURE = 'GET_RESOURCE_ITEM_FAILURE';

export const GET_ASSIGNMENT_INIT = 'GET_ASSIGNMENT_INIT';
export const GET_ASSIGNMENT_SUCCESS = 'GET_ASSIGNMENT_SUCCESS';
export const GET_ASSIGNMENT_FAILURE = 'GET_ASSIGNMENT_FAILURE';

export const EDIT_CALENDAR_EVENT_INIT = 'EDIT_CALENDAR_EVENT_INIT';
export const EDIT_CALENDAR_EVENT_SUCCESS = 'EDIT_CALENDAR_EVENT_SUCCESS';
export const EDIT_CALENDAR_EVENT_FAILURE = 'EDIT_CALENDAR_EVENT_FAILURE';

export const SET_SELECTED_EVENT = 'SET_SELECTED_EVENT';
export const RESET_SELECTED_EVENT = 'RESET_SELECTED_EVENT';

//USERS
export const CHANGE_TAB_INDEX_USERS = 'CHANGE_TAB_INDEX_USERS';
//#1
export const GET_ALL_USERS_INIT = 'GET_ALL_USERS_INIT';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_ALL_USERS_FAILURE = 'GET_ALL_USERS_FAILURE';
//#2
export const GET_DEPARTMENTS_INIT = 'GET_DEPARTMENTS_INIT';
export const GET_DEPARTMENTS_SUCCESS = 'GET_DEPARTMENTS_SUCCESS';
export const GET_DEPARTMENTS_FAILURE = 'GET_DEPARTMENTS_FAILURE';
//#3
export const GET_USER_INIT = 'GET_USER_INIT';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';
//#4
export const ADD_NEW_USER_INIT = 'ADD_NEW_USER_INIT';
export const ADD_NEW_USER_SUCCESS = 'ADD_NEW_USER_SUCCESS';
export const ADD_NEW_USER_FAILURE = 'ADD_NEW_USER_FAILURE';
//#5
export const EDIT_USER_INIT = 'EDIT_USER_INIT';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_FAILURE = 'EDIT_USER_FAILURE';
//#6
export const EDIT_USER_STATUS_INIT = 'EDIT_USER_STATUS_INIT';
export const EDIT_USER_STATUS_SUCCESS = 'EDIT_USER_STATUS_SUCCESS';
export const EDIT_USER_STATUS_FAILURE = 'EDIT_USER_STATUS_FAILURE';
//#7
export const DELETE_USER_INIT = 'DELETE_USER_INIT';
export const DELETE_USER_SUCCESS = 'EDIT_USER_STATUS_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';
//#8
export const GET_AVAILABLE_PERMISSIONS_INIT = 'GET_AVAILABLE_PERMISSIONS_INIT';
export const GET_AVAILABLE_PERMISSIONS_SUCCESS = 'GET_AVAILABLE_PERMISSIONS_SUCCESS';
export const GET_AVAILABLE_PERMISSIONS_FAILURE = 'GET_AVAILABLE_PERMISSIONS_FAILURE';
//#9
export const RESET_USER_DETAILS = 'RESET_USER_DETAILS';
//#0
export const RESET_ALL_USERS = 'RESET_ALL_USERS';

//Permissions
export const SET_MY_PERMISSIONS_IN_REDUX = 'SET_MY_PERMISSIONS_IN_REDUX';
//RECEIVING
//#1
export const GET_RECEIVING_TABLE_LIST_INIT = 'GET_RECEIVING_TABLE_LIST_INIT';
export const GET_RECEIVING_TABLE_LIST_SUCCESS = 'GET_RECEIVING_TABLE_LIST_SUCCESS';
export const GET_RECEIVING_TABLE_LIST_FAILURE = 'GET_RECEIVING_TABLE_LIST_FAILURE';
//#2
export const GET_RECEIVING_DETAILS_INIT = 'GET_RECEIVING_DETAILS_INIT';
export const GET_RECEIVING_DETAILS_SUCCESS = 'GET_RECEIVING_DETAILS_SUCCESS';
export const GET_RECEIVING_DETAILS_FAILURE = 'GET_RECEIVING_DETAILS_FAILURE';
//#3
export const GET_RECEIVING_ITEMS_INIT = 'GET_RECEIVING_ITEMS_INIT';
export const GET_RECEIVING_ITEMS_SUCCESS = 'GET_RECEIVING_ITEMS_SUCCESS';
export const GET_RECEIVING_ITEMS_FAILURE = 'GET_RECEIVING_ITEMS_FAILURE';
//#4
export const GET_RECEIVING_HISTORY_INIT = 'GET_RECEIVING_HISTORY_INIT';
export const GET_RECEIVING_HISTORY_SUCCESS = 'GET_RECEIVING_HISTORY_SUCCESS';
export const GET_RECEIVING_HISTORY_FAILURE = 'GET_RECEIVING_HISTORY_FAILURE';

//#5
export const ADD_RECEIVING_DETAILS_INIT = 'ADD_RECEIVING_DETAILS_INIT';
export const ADD_RECEIVING_DETAILS_SUCCESS = 'ADD_RECEIVING_DETAILS_SUCCESS';
export const ADD_RECEIVING_DETAILS_FAILURE = 'ADD_RECEIVING_DETAILS_FAILURE';

//#7
export const GET_RECEIVING_FILES_INIT = 'GET_RECEIVING_FILES_INIT';
export const GET_RECEIVING_FILES_SUCCESS = 'GET_RECEIVING_FILES_SUCCESS';
export const GET_RECEIVING_FILES_FAILURE = 'GET_RECEIVING_FILES_FAILURE';
// ERRORS
export const SET_ERROR = 'SET_ERROR';
export const REMOVE_ERROR = 'REMOVE_ERROR';
export const REMOVE_RECEIVING_ERROR = 'REMOVE_RECEIVING_ERROR';

// Time Management
export const GET_USERS_TIME_LIST = 'GET_USERS_TIME_LIST';
export const SET_LOGIN_BY_PIN_ERROR = 'SET_LOGIN_BY_PIN_ERROR';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const RESET_ERRORS = 'RESET_ERRORS';
export const ENTER_PIN = 'ENTER_PIN';
export const ENTER_UID = 'ENTER_UID';
export const GET_DAILY_CARD_DATA = 'GET_DAILY_CARD_DATA';
export const CLOCK_OUT = 'CLOCK_OUT';
export const GET_USER_NAME = 'GET_USER_NAME';
export const SET_LUNCH_ERROR = 'SET_LUNCH_ERROR';
export const SUBMIT_HOURS_ERROR = 'SUBMIT_HOURS_ERROR';
export const SET_DIALY_REVIEWS = 'SET_DIALY_REVIEWS';
export const LOGOUT_TIME_MANAGEMENT = 'LOGOUT_TIME_MANAGEMENT';
export const GET_WEEKLY_REVIEWS = 'GET_WEEKLY_REVIEWS';
export const GET_WEEKLY_REVIEW = 'GET_WEEKLY_REVIEW';
export const GET_WEEKLY_HISTORY = ' GET_WEEKLY_HISTORY';
export const PARAMS_FOR_EXPORT_REPORT = 'PARAMS_FOR_EXPORT_REPORT';
export const GET_WEEKLY_EMPLOYEES = 'GET_WEEKLY_EMPLOYEES';
export const UPDATE_WEEKLY_REVIEW = 'UPDATE_WEEKLY_REVIEW';
export const WEEKLY_REVIEW_MODAL = 'WEEKLY_REVIEW_MODAL'
export const REMOVE_WEEKLY_REVIEWS  = 'REMOVE_WEEKLY_REVIEWS '
export const EXPORT_REPORT  = 'EXPORT_REPORT'
