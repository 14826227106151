import {
  CHANGE_TAB_INDEX_USERS,

  GET_ALL_USERS_INIT,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_FAILURE,

  GET_DEPARTMENTS_INIT,
  GET_DEPARTMENTS_SUCCESS,
  GET_DEPARTMENTS_FAILURE,

  GET_USER_INIT,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,

  ADD_NEW_USER_INIT,
  ADD_NEW_USER_SUCCESS,
  ADD_NEW_USER_FAILURE,

  EDIT_USER_INIT,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAILURE,

  EDIT_USER_STATUS_INIT,
  EDIT_USER_STATUS_SUCCESS,
  EDIT_USER_STATUS_FAILURE,

  DELETE_USER_INIT,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,

  GET_AVAILABLE_PERMISSIONS_INIT,
  GET_AVAILABLE_PERMISSIONS_SUCCESS,
  GET_AVAILABLE_PERMISSIONS_FAILURE,

  RESET_USER_DETAILS,
  RESET_ALL_USERS,
} from '../constantTypes';

import {combineReducers} from 'redux';

function tabIndexReducer(state = 0, {type, payload}) {
  switch (type) {
    case CHANGE_TAB_INDEX_USERS: {
      return payload;
    }
    default:
      return state;
  }
}

const allUsersState = {
  loading: false,
  loaded: false,
  errors: false,
  dataActive: [],
  dataDeactive: [],
};

function allUsersReducer(state = allUsersState, {type, payload, active}) {
  switch (type) {
    case GET_ALL_USERS_INIT:
      return {...state, loading: true, loaded: false, error: false};
    case GET_ALL_USERS_SUCCESS:
      if( active === 1) {
        return {
          ...state,
          loading: false,
          loaded: true,
          errors: false,
          dataActive: payload,
        };
      } else  {
        return {
          ...state,
          loading: false,
          loaded: true,
          errors: false,
          dataDeactive: payload,
        };
      }
    case GET_ALL_USERS_FAILURE:
      return {...state, loading: false, loaded: false, error: true};

    case EDIT_USER_STATUS_INIT:
      return {
        ...state,
        loading: true
      };
    case EDIT_USER_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: [],
      }
    case EDIT_USER_STATUS_FAILURE:
      return {
        ...state,
        errors: payload
      }

    case DELETE_USER_INIT:
      return {
        ...state,
        loading: true,
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: [],
      }
    case DELETE_USER_FAILURE:
      return {
        ...state,
        errors: payload
      }

    case RESET_ALL_USERS:
      return allUsersState;
    default:
      return state;
  }
}


const departmentState = {
  loading: false,
  loaded: false,
  error: false,
  data: {},
};

function departmentReducer(state = departmentState, {type, payload}) {
  switch (type) {
    case GET_DEPARTMENTS_INIT:
      return {...state, loading: true, loaded: false, error: false};
    case GET_DEPARTMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        data: payload,
      };
    case GET_DEPARTMENTS_FAILURE:
      return {...state, loading: false, loaded: false, error: true};
    default:
      return state;
  }
}

const userState = {
  loading: false,
  loaded: false,
  error: false,
  failure: null,
  item: {},
};

function userReducer(state = userState, {type, payload}) {
  switch (type) {
    case GET_USER_INIT:
      return {...state, loading: true, loaded: false, error: false};
    case GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        item: payload,
      };
    case GET_USER_FAILURE:
      return {...state, loading: false, loaded: false, error: true};

    case ADD_NEW_USER_INIT:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
        failure: null,
      };
    case ADD_NEW_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        failure: null,
      };
    case ADD_NEW_USER_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
        failure: payload,
      };

    case EDIT_USER_INIT:
      return {
        ...state,
        loading: true,
      };
    case EDIT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case EDIT_USER_FAILURE:
      return {
        ...state,
        failure: payload
      }
    case RESET_USER_DETAILS:
      return {
        loading: false,
        loaded: false,
        error: false,
        failure: null,
        item: {},
      }
    default:
      return state;
  }
}

//#8
const permissionsState = {
  loading: false,
  loaded: false,
  error: false,
  data: {},
};

function permissionsReducer(state = permissionsState, {type, payload}) {
  switch (type) {
    case GET_AVAILABLE_PERMISSIONS_INIT:
      return {...state, loading: true, loaded: false, error: false};
    case   GET_AVAILABLE_PERMISSIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        data: payload,
      };
    case GET_AVAILABLE_PERMISSIONS_FAILURE:
      return {...state, loading: false, loaded: false, error: true};
    default:
      return state;
  }
}

export default combineReducers({
  tabIndex: tabIndexReducer,
  usersData: allUsersReducer,
  userItem: userReducer,
  departments: departmentReducer,
  permissions: permissionsReducer,
});
