import {
  FETCH_DATA_ERROR,
  FETCH_DATA_START,
  FETCH_DATA_SUCCESS,
} from '../constantTypes';

const initialState = {
  loading: false,
  isError: false,
  errors: [],
  data: []
};

const exampleReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DATA_START:
      return {
        ...state,
        loading: true,
      };
    case FETCH_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case FETCH_DATA_ERROR:
      return {
        ...state,
        isError: true,
        errors: action.payload,
        loading: false
      };
    default:
      return state
  }
};

export default exampleReducer;

