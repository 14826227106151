import {
  FETCH_DATA_ERROR,
  FETCH_DATA_START,
  FETCH_DATA_SUCCESS,
  FETCH_PURCHASE_VENDOR_LIST_START,
  FETCH_PURCHASE_VENDOR_LIST_SUCCESS,
  FETCH_PURCHASE_VENDOR_LIST_ERROR,
  FETCH_DETAILS_START,
  FETCH_DETAILS_SUCCESS,
  FETCH_DETAILS_ERROR,
  FETCH_DETAILSLIST_START,
  FETCH_DETAILSLIST_SUCCESS,
  FETCH_DETAILSLIST_ERROR,
  CREATE_NEW_CONTACT,
  UPDATE_NEW_CONTACT,
  UPDATE_EXIST_CONTACT,
  DELETE_EXIST_CONTACT,
  SEARCH_VENDORS_START,
  SEARCH_VENDORS_SUCCESS,
  SEARCH_VENDORS_ERROR,
} from '../constantTypes';

const initialState = {
  loading: false,
  isError: false,
  errors: [],
  data: [],
  vendors: [],
  updated_contacts: [],
  created_contacts: [],
  deleted_contacts: [],
  details: [],
  list: [],
  detailsList: {},
  errorsDetails: [],
  withSearch: false,
  searchField: false
};

const purchaseReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DATA_START:
      return {
        ...state,
        loading: true,
      };
    case FETCH_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case FETCH_DATA_ERROR:
      return {
        ...state,
        isError: true,
        errors: action.payload,
        loading: false
      };
    case FETCH_PURCHASE_VENDOR_LIST_START:
      return {
        ...state,
        loading: true,
      };
    case FETCH_PURCHASE_VENDOR_LIST_SUCCESS:
      return {
        ...state,
        vendors: action.payload,
        loading: false
      };
    case FETCH_PURCHASE_VENDOR_LIST_ERROR:
      return {
        ...state,
        isError: true,
        errors: action.payload,
        loading: false
      };
    case FETCH_DETAILS_START:
      return {
        ...state,
        loading: true,
      };
    case FETCH_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload,
        list: action.payload.vendors.items,
        loading: false
      };
    case FETCH_DETAILS_ERROR:
      return {
        ...state,
        isError: true,
        errors: action.payload,
        loading: false
      };
    case FETCH_DETAILSLIST_START:
      return {
        ...state,
        loading: true
      };
    case FETCH_DETAILSLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        detailsList: action.detailsList,
        updated_contacts:  action.updated_contacts,
        created_contacts: [],
        deleted_contacts: []
      };
    case FETCH_DETAILSLIST_ERROR:
      return {
        ...state,
        errorsDetails: action.payload
      }
    case CREATE_NEW_CONTACT:
      let contacts = action.payload.length === 0 ? [] : [...state.created_contacts, ...action.payload];
      return {
        ...state,
        created_contacts: contacts
      }
    case UPDATE_NEW_CONTACT:
      let newData = [...state.created_contacts];
      newData.find(el => (el.identify || el.id)  === action.contactId)[action.fieldName] = action.data;
      return{
        ...state,
        created_contacts : newData
      }
    case UPDATE_EXIST_CONTACT:
      let newDataExistContacts = [...state.updated_contacts];
      newDataExistContacts.find(el => el.id === action.contactId)[action.fieldName] = action.data;
    return {
      ...state,
      updated_contacts: newDataExistContacts
    }
    case DELETE_EXIST_CONTACT:
      if(action.data_type === 'created') {
        return {
          ...state,
          created_contacts: [...state.created_contacts]
            .filter(el => (el.id || el.identify) !== action.itemId),
        }
      }else{
        return {
          ...state,
          deleted_contacts: [...state.deleted_contacts, action.itemId],
          updated_contacts: [...state.updated_contacts]
            .filter(el => el.id !== action.itemId),
        }
      }
    case SEARCH_VENDORS_START:
      return {
        ...state,
        loading: true
      }
    case SEARCH_VENDORS_SUCCESS:
      return {
        ...state,
        loading: false,
        vendors: action.payload,
        errors: [],
        searchField: action.searchField,

      }
    case SEARCH_VENDORS_ERROR:
    return {
      ...state,
      errors: action.payload
    }
    default:
      return state
  }
};

export default purchaseReducer;
