import {
    FETCH_CHILD_QUOTES_LIST_START,
    FETCH_CHILD_QUOTES_LIST_SUCCESS,
    FETCH_CHILD_QUOTES_LIST_ERROR,
    DELETE_CHILD_QUOTE,
    DELETE_CHILD_QUOTE_ERROR,
    CLONE_CHILD_QUOTE_START,
    CLONE_CHILD_QUOTE_SUCCESS,
    CLONE_CHILD_QUOTE_ERROR,
    CHILD_QUOTE_REVISION_START,
    CHILD_QUOTE_REVISION_SUCCESS,
    CHILD_QUOTE_REVISION_ERROR,
    SET_CHILD_QUOTE_ITEM,
    SET_CHILD_QUOTE_ITEM_ERROR,
    EDIT_CHILD_QUOTE_ERROR,
    EDIT_CHILD_QUOTE,
    EDIT_CHILD_QUOTE_ITEM,
    EDIT_CHILD_QUOTE_ITEM_ERROR,
    EDIT_CHILD_QUOTE_ITEM_START,
    CHILD_QUOTE_STATUS_START,
    CHILD_QUOTE_STATUS_SUCCESS,
    CHILD_QUOTE_STATUS_ERROR,
    SEARCH_CHILD_QUOTE_START,
    SEARCH_CHILD_QUOTE_SUCCESS,
    SEARCH_CHILD_QUOTE_ERROR
} from '../constantTypes';

import * as R from 'ramda';

const initialState = {
    loading: false,
    isError: false,
    errors: [],
    list: [],
    child_quotes: [],
    selectedIds: [],
    childQuoteItem: '',
    childQuoteItemError: '',
    editQuoteItem: '',
    editQuoteItemError: '',
    childQuoteItemData: '',
    childQuoteItemDataError: '',
    revisionList: [],
    dataLoading: false,
    error404: '',
    searchField: false
};

const childQuotesReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CHILD_QUOTES_LIST_START:
            return {
                ...state,
                loading: true,
            };
        case FETCH_CHILD_QUOTES_LIST_SUCCESS:
            return {
                ...state,
                list: action.payload,
                loading: false,
                error404: ''
            };
        case FETCH_CHILD_QUOTES_LIST_ERROR:
            return {
                ...state,
                isError: true,
                errors: action.payload,
                error404: action.error404,
                loading: false,
            };
        case DELETE_CHILD_QUOTE:
            const filtered = el => !R.equals(el.id, action.payload);
            return {
                ...state,
                list: R.filter(filtered, state.list),
            };
        case DELETE_CHILD_QUOTE_ERROR:
            return {
                ...state,
                errors: action.payload,
            };
        case CLONE_CHILD_QUOTE_START:
            return {
                ...state,
                loading: true,
            };
        case CLONE_CHILD_QUOTE_SUCCESS:
            return {
                ...state,
                list: action.payload,
                loading: false,
            };
        case CLONE_CHILD_QUOTE_ERROR:
            return {
                ...state,
                errors: action.payload,
            };

        case CHILD_QUOTE_REVISION_START:
            return {
                ...state,
                loading: true,
            };
        case CHILD_QUOTE_REVISION_SUCCESS:
            return {
                ...state,
                revisionList: action.payload,
                loading: false,
            };
        case CHILD_QUOTE_REVISION_ERROR:
            return {
                ...state,
                errors: action.payload,
            };
        case SET_CHILD_QUOTE_ITEM:
            return {
                ...state,
                childQuoteItem: action.payload,
            };
        case SET_CHILD_QUOTE_ITEM_ERROR:
            return {
                ...state,
                childQuoteItemError: action.payload,
            };
        case EDIT_CHILD_QUOTE:
            return {
                ...state,
                childQuoteItem: action.payload,
            };
        case EDIT_CHILD_QUOTE_ERROR:
            return {
                ...state,
                childQuoteItemError: action.payload,
            };
        case EDIT_CHILD_QUOTE_ITEM_START:
          return {
            ...state,
            dataLoading: true
          }
        case EDIT_CHILD_QUOTE_ITEM:
            return {
                ...state,
                childQuoteItemData: action.payload,
                dataLoading: false
            };
        case EDIT_CHILD_QUOTE_ITEM_ERROR:
            return {
                ...state,
                childQuoteItemDataError: action.payload,
           };
        case CHILD_QUOTE_STATUS_START:
          return {
            ...state,
            loading: true
          };
        case CHILD_QUOTE_STATUS_SUCCESS:
          const currentData = R.find(R.propEq('id', action.payload.id), state.list.items); // vendor_id
          const dataPosition = R.indexOf(currentData, state.list.items);
          currentData[action.payload] = action.payload.data;
          let list = {...state.list};
          list.items = R.update(dataPosition, currentData, state.list.items);
          return {
            ...state,
            loading: false,
            statusErrors: [],
            list
          }
        case CHILD_QUOTE_STATUS_ERROR:
          return {
            ...state,
            statusErrors: action.payload
          }

          case SEARCH_CHILD_QUOTE_START:
              return {
                  ...state,
                  loading: true,
              };
          case SEARCH_CHILD_QUOTE_SUCCESS:
              return {
                  ...state,
                  list: action.payload,
                  loading: false,
                  searchField: action.searchField,
              };
          case SEARCH_CHILD_QUOTE_ERROR:
              return {
                  ...state,
                  errors: action.payload,
              };
        default:
            return state;
    }
};

export default childQuotesReducer;

