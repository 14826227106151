import {
  FETCH_PARTS_APPROVAL_START,
  FETCH_PARTS_APPROVAL_SUCCESS,
  FETCH_PARTS_APPROVAL_ERROR,
  FETCH_PRODUCT_TYPES_START,
  FETCH_PRODUCT_TYPES_SUCCESS,
  FETCH_PRODUCT_TYPES_ERROR,
  SET_PARTS_APPROVE_IDS
} from '../constantTypes';
import * as R from 'ramda';

const initialState = {
  loading: false,
  isError: false,
  errors: [],
  list: [],
  product_types: [],
  selectedIds: [],
  fullInfo: [],
  value: ""
};

const partsApprovalReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PARTS_APPROVAL_START:
      return {
        ...state,
        loading: true,
      };
    case FETCH_PARTS_APPROVAL_SUCCESS:
      let data = state.list;
      let newArray = action.payload;
      const editionalInfo = [];
      for(let i= 0; i < data.length; i++) {

        newArray.map(item => {
          if(item.id !== data[i].id) {
            editionalInfo.push(item)
          }
        })
      }
      return {
        ...state,
        list: action.payload,
        fullInfo: action.fullInfo,
        loading: false,
        value: action.value
      };
    case FETCH_PARTS_APPROVAL_ERROR:
      return {
        ...state,
        isError: true,
        errors: action.payload,
      };
    case FETCH_PRODUCT_TYPES_START:
      return {
        ...state,
        loading: true,
      };
    case FETCH_PRODUCT_TYPES_SUCCESS:
      return {
        ...state,
        product_types: action.payload,
        loading: false
      };
    case FETCH_PRODUCT_TYPES_ERROR:
      return {
        ...state,
        isError: true,
        errors: action.payload,
        loading: false
      };
    case SET_PARTS_APPROVE_IDS:
      let ids = R.clone(state.selectedIds);
      const isEqual = item => action.payload !== item;
      if (action.payload && !R.includes(action.payload, ids)) {
        ids.push(action.payload)
      } else if (action.payload && R.includes(action.payload, ids)) {
        ids = R.filter(isEqual, ids)
      } else {
        ids = []
      }
      return {
        ...state,
        selectedIds: ids
      };
    default:
      return state
  }
};

export default partsApprovalReducer;

