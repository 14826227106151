import {
    FETCH_QUOTES_LIST_START,
    FETCH_QUOTES_LIST_SUCCESS,
    FETCH_QUOTES_LIST_ERROR,
    DELETE_QUOTE,
    DELETE_QUOTE_ERROR,
    GET_QUOTE_DATA_NUMBER,
    GET_QUOTE_DATA_NUMBER_ERROR,
    POST_QUOTE,
    POST_QUOTE_ERROR,
    GET_CUSTOMERS_LIST,
    GET_CUSTOMERS_LIST_ERROR,
    GET_COMPANIES_LIST,
    GET_COMPANIES_LIST_ERROR,
    CLONE_QUOTE_START,
    CLONE_QUOTE_SUCCESS,
    CLONE_QUOTE_ERROR,
    PARENT_QUOTE_STATUS_START,
    PARENT_QUOTE_STATUS_ERROR,
    PARENT_QUOTE_STATUS_SUCCESS,
    SEARCH_PARENT_QUOTE_START,
    SEARCH_PARENT_QUOTE_SUCCESS,
    SEARCH_PARENT_QUOTE_ERROR,
} from '../constantTypes';
import * as R from 'ramda';

const initialState = {
    loading: false,
    isError: false,
    errors: [],
    list: [],
    parent_quotes: [],
    selectedIds: [],
    no: null,
    pagination: [],
    errorsThirdLinePage: {},
    customers_list: null,
    statusErrors: [],
    searchField: false
};

const quotesReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_QUOTES_LIST_START:
            return {
                ...state,
                loading: true,
            };
        case FETCH_QUOTES_LIST_SUCCESS:
            return {
                ...state,
                list: action.payload,
                pagination: action.pagination,
                loading: false,
            };
        case FETCH_QUOTES_LIST_ERROR:
            return {
                ...state,
                isError: true,
                errors: action.payload,
                loading: false,
            };
        case DELETE_QUOTE:
            const filtered = el => !R.equals(el.id, action.payload);
            return {
                ...state,
                list: R.filter(filtered, state.list),
                loading: false,
            };
        case DELETE_QUOTE_ERROR:
            return {
                ...state,
                isError: true,
                errors: action.payload,
                loading: false,
            };
        case GET_QUOTE_DATA_NUMBER:
            return {
                ...state,
                no: action.payload,
            };
        case GET_QUOTE_DATA_NUMBER_ERROR:
            return {
                ...state,
                errors: action.payload,
            };
        case POST_QUOTE:
            return {
                ...state,
                list: state.list.concat(action.payload),
                errorsThirdLinePage: {}
            };
        case POST_QUOTE_ERROR:
            return {
                ...state,
                errorsThirdLinePage: action.payload,
            };
        case GET_CUSTOMERS_LIST:
            return {
                ...state,
                customers_list: action.payload,
            };
        case GET_CUSTOMERS_LIST_ERROR:
            return {
                ...state,
                errors: action.payload,
            };
        case GET_COMPANIES_LIST:
            return {
                ...state,
                companies_list: action.payload,
            };
        case GET_COMPANIES_LIST_ERROR:
            return {
                ...state,
                errors: action.payload,
            };
        case CLONE_QUOTE_START:
            return {
                ...state,
                loading: true,
            };
        case CLONE_QUOTE_SUCCESS:
            return {
                ...state,
                list: action.payload,
            };
        case CLONE_QUOTE_ERROR:
            return {
                ...state,
                errors: action.payload,
            };
        case PARENT_QUOTE_STATUS_START:
          return {
            ...state,
            loading: true
          };
        case PARENT_QUOTE_STATUS_SUCCESS:
          const currentData = R.find(R.propEq('id', action.payload.id), state.list); // vendor_id
          const dataPosition = R.indexOf(currentData, state.list);
          currentData[action.payload] = action.payload.data;
          return {
            ...state,
            loading: false,
            statusErrors: [],
            list: R.update(dataPosition, currentData, state.list )
          }
        case PARENT_QUOTE_STATUS_ERROR:
          return {
            ...state,
            statusErrors: action.payload
          }
          case SEARCH_PARENT_QUOTE_START:
              return {
                  ...state,
                  loading: true,
              };
          case SEARCH_PARENT_QUOTE_SUCCESS:
              return {
                  ...state,
                  list: action.payload,
                  pagination: action.pagination,
                  loading: false,
                  searchField: action.searchField,
              };
          case SEARCH_PARENT_QUOTE_ERROR:
              return {
                  ...state,
                  isError: true,
                  errors: action.payload,
                  loading: false,
              };
        default:
            return state;
    }
};

export default quotesReducer;
