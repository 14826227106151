import {
    GET_CLIENTS_LIST_START,
    GET_CLIENTS_LIST_SUCCESS,
    GET_CLIENTS_LIST_ERROR,
    FETCH_DETAILSLIST_CLIENTS_START,
    FETCH_DETAILSLIST_CLIENTS_SUCCESS,
    FETCH_DETAILSLIST_CLIENTS_ERROR,
    DELETE_EXIST_CLIENT_CONTACT,
    UPDATE_EXIST_CLIENT_CONTACT,
    UPDATE_NEW_CLIENT_CONTACT,
    CREATE_NEW_CLIENT_CONTACT
} from '../constantTypes';

const initialState = {
    loading: false,
    data: [],
    errors: [],
    values: '',
    updated_contacts: [],
    created_contacts: [],
    deleted_contacts: [],
    details: [],
    list: [],
    detailsList: {},
    errorsDetails: [],
    withSearch: false,
    searchField: false
};
const clientsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CLIENTS_LIST_START:
            return {
                ...state,
                loading: true,
            };
        case GET_CLIENTS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                values: action.value,
            };
        case GET_CLIENTS_LIST_ERROR:
            return {
                ...state,
                errors: action.payload,
            };
        case FETCH_DETAILSLIST_CLIENTS_START:
            return {
                ...state,
                loading: true,
            };
        case FETCH_DETAILSLIST_CLIENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                detailsList: action.detailsList,
                updated_contacts: action.updated_contacts,
                created_contacts: [],
                deleted_contacts: [],
            };
        case FETCH_DETAILSLIST_CLIENTS_ERROR:
            return {
                ...state,
                errorsDetails: action.payload,
            };

            case CREATE_NEW_CLIENT_CONTACT:
                let contacts = [...state.created_contacts];
                contacts.push(action.payload);
                return {
                  ...state,
                  created_contacts: contacts
                }
              case UPDATE_NEW_CLIENT_CONTACT:
                let newData = [...state.created_contacts];
                newData[action.id][action.value] = action.data;
                return{
                  ...state,
                  created_contacts : newData
                }
              case UPDATE_EXIST_CLIENT_CONTACT:
                let newDataExistContacts = [...state.updated_contacts];
                newDataExistContacts[action.id][action.value] = action.data;
              return {
                ...state,
                updated_contacts: newDataExistContacts
              }
              case DELETE_EXIST_CLIENT_CONTACT:
                let deletedData;
                let deletedItems = [...state.deleted_contacts];
                deletedItems.push(action.itemId);
                if(action.data_type === 'create') {
                  deletedData = [...state.created_contacts];
                  deletedData.splice( action.index,1);
                  return {
                    ...state,
                    created_contacts: deletedData
                  }
                }else{
                  deletedData = [...state.updated_contacts];
                  deletedData.splice( action.index,1);

                  return {
                    ...state,
                    deleted_contacts: deletedItems,
                    updated_contacts: deletedData
                  }
                }
        default:
            return state;
    }
};

export default clientsReducer;
