import {
  FETCH_COUNTRIES_START,
  FETCH_COUNTRIES_SUCCESS,
  FETCH_COUNTRIES_ERROR,
  FETCH_CURRENCIES_START,FETCH_CURRENCIES_SUCCESS, FETCH_CURRENCIES_ERROR,
  FETCH_TAXES_START, FETCH_TAXES_SUCCESS, FETCH_TAXES_ERROR
} from '../constantTypes';

const initialState = {
  loading: false,
  isError: false,
  errors: [],
  countries: [],
  taxes: [],
  currencies: []
};

const otherReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COUNTRIES_START:
      return {
        ...state,
        loading: true,
      };
    case FETCH_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: action.payload,
        loading: false
      };
    case FETCH_COUNTRIES_ERROR:
      return {
        ...state,
        isError: true,
        errors: action.payload,
        loading: false
      };
    case FETCH_CURRENCIES_START:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CURRENCIES_SUCCESS:
      return {
        ...state,
        currencies: action.payload,
        loading: false
      };
    case FETCH_CURRENCIES_ERROR:
      return {
        ...state,
        isError: true,
        errors: action.payload,
        loading: false
      };
    case FETCH_TAXES_START:
      return {
        ...state,
        loading: true,
      };
    case FETCH_TAXES_SUCCESS:
      return {
        ...state,
        taxes: action.payload,
        loading: false
      };
    case FETCH_TAXES_ERROR:
      return {
        ...state,
        isError: true,
        errors: action.payload,
        loading: false
      };
    default:
      return state
  }
};

export default otherReducer;

