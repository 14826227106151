import {
  GET_JOB_LIST_INIT,
  GET_JOB_LIST_SUCCESS,
  GET_JOB_LIST_FAILURE,
  GET_LABOUR_TYPES_INIT,
  GET_LABOUR_TYPES_SUCCESS,
  GET_LABOUR_TYPES_FAILURE,
  GET_CUSTOMER_INIT,
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMER_FAILURE,
  ADD_NEW_CALENDAR_EVENT_INIT,
  ADD_NEW_CALENDAR_EVENT_SUCCESS,
  ADD_NEW_CALENDAR_EVENT_FAILURE,
  RESET_MODAL_FIELDS,
  GET_EMPLOYEES_INIT,
  GET_EMPLOYEES_SUCCESS,
  GET_EMPLOYEES_FAILURE,
  GET_ASSIGNMENT_INIT,
  GET_ASSIGNMENT_SUCCESS,
  GET_ASSIGNMENT_FAILURE,
  SET_CURRENT_DAY,

  EDIT_CALENDAR_EVENT_INIT,
  EDIT_CALENDAR_EVENT_SUCCESS,
  EDIT_CALENDAR_EVENT_FAILURE,

  SET_SELECTED_EVENT,
  SET_SELECTED_EVENT_POSITION,
  RESET_SELECTED_EVENT,
} from '../constantTypes';

import {combineReducers} from 'redux';

const jobListState = {
  loading: false,
  loaded: false,
  error: false,
  data: [],
};

function jobListReducer(state = jobListState, { type, payload }) {
  switch (type) {
    case GET_JOB_LIST_INIT:
      return {...state, loading: true, loaded: false, error: false};
    case GET_JOB_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        data: payload,
      };
    case GET_JOB_LIST_FAILURE:
      return {...state, loading: false, loaded: false, error: true};
    default:
      return state;
  }
}

const laboursTypeState = {
  loading: false,
  loaded: false,
  error: false,
  data: [],
};

function laboursTypeReducer(state = laboursTypeState, {type, payload}) {
  switch (type) {
    case GET_LABOUR_TYPES_INIT:
      return {...state, loading: true, loaded: false, error: false};
    case GET_LABOUR_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        data: payload,
      };
    case GET_LABOUR_TYPES_FAILURE:
      return {...state, loading: false, loaded: false, error: true};
    case RESET_MODAL_FIELDS:
      return {
        ...laboursTypeState,
      };
    default:
      return state;
  }
}

const usersState = {
  loading: false,
  loaded: false,
  error: false,
  data: [],
};

function usersReducer(state = usersState, {type, payload}) {
  switch (type) {
    case GET_CUSTOMER_INIT:
      return {...state, loading: true, loaded: false, error: false};
    case GET_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        data: payload,
      };
    case GET_CUSTOMER_FAILURE:
      return {...state, loading: false, loaded: false, error: true};
    case RESET_MODAL_FIELDS:
      return {
        ...usersState,
      };
    default:
      return state;
  }
}

const assignmentState = {
  sending: false,
  sent: false,
  error: false,
  failure: null,
  data: null,
};

function assignmentReducer(state = assignmentState, {type, payload}) {
  switch (type) {
    case ADD_NEW_CALENDAR_EVENT_INIT:
      return {
        ...state,
        sending: true,
        sent: false,
        error: false,
        failure: null,
      };
    case ADD_NEW_CALENDAR_EVENT_SUCCESS:
      return {
        ...state,
        sending: false,
        sent: true,
        error: false,
        failure: null,
      };
    case ADD_NEW_CALENDAR_EVENT_FAILURE:
      return {
        ...state,
        sending: false,
        sent: false,
        error: true,
        failure: payload.response.data.data.errors,
      };
    case EDIT_CALENDAR_EVENT_INIT:
      return {
        ...state,
        sending: true,
        sent: false,
        error: false,
        failure: null,
      };
    case EDIT_CALENDAR_EVENT_SUCCESS:
      return {
        ...state,
        sending: false,
        sent: true,
        error: false,
        failure: null,
      };
    case EDIT_CALENDAR_EVENT_FAILURE:
      return {
        ...state,
        sending: false,
        sent: false,
        error: true,
        failure: payload.response.data.data.errors,
      };
    case GET_ASSIGNMENT_INIT:
      return {...state, loading: true, loaded: false, error: false};
    case GET_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        data: payload,
      };
    case GET_ASSIGNMENT_FAILURE:
      return {...state, loading: false, loaded: false, error: true};

    case RESET_MODAL_FIELDS:
      return {
        ...state,
        data: null,
        error:false,
        failure: null,
      };
    default:
      return state;
  }
}

const employeesState = {
  loading: false,
  loaded: false,
  error: false,
  data: [],
};

function employeesReducer(state = employeesState, {type, payload}) {
  switch (type) {
    case GET_EMPLOYEES_INIT:
      return {...state, loading: true, loaded: false, error: false};
    case GET_EMPLOYEES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        data: payload,
      };
    case GET_EMPLOYEES_FAILURE:
      return {...state, loading: false, loaded: false, error: true};
    default:
      return state;
  }
}

const selectedEventState = {id: null, top: 0, left: 0, name: ''};

function selectedEventReducer(state = selectedEventState, {type, payload}) {
  switch (type) {
    case SET_SELECTED_EVENT:
      return payload;
      case RESET_SELECTED_EVENT:
      return {id: null, top: 0, left: 0, name: ''};
    default:
      return {id: null, top: 0, left: 0, name: ''};
  }
}

const calendarHeaderState = {
  currentDay: null,
  startDate: null,
  endDate: null,
};

function calendarHeaderReducer(state = calendarHeaderState, {type, payload}) {
  switch (type) {
    case SET_CURRENT_DAY:
      return {
        ...state,
        currentDay: payload,
        startDate:  payload.startOf('week').format('YYYY-MM-DD'),
        endDate:  payload.endOf('week').format('YYYY-MM-DD'),
      };
    default:
      return state;
  }
}


export default combineReducers({
  jobsList: jobListReducer,
  laboursType: laboursTypeReducer,
  users: usersReducer,
  assignment: assignmentReducer,
  employees: employeesReducer,
  calendarHeader: calendarHeaderReducer,
  selectedEvent: selectedEventReducer,
});
