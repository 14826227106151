import * as R from 'ramda';
import {
  FETCH_STOCK_LIST_START,
  CHANGE_VENDOR_NAME,
  FETCH_STOCK_LIST_SUCCESS,
  FETCH_STOCK_LIST_ERROR,
  SELECT_VENDOR,
  CHANGE_VENDOR_QTY,
  GET_FULL_STOCK_LIST_DATA_START,
  GET_FULL_STOCK_LIST_DATA_SUCCESS,
  GET_FULL_STOCK_LIST_DATA_ERROR,
  SEARCH_STOCK_LIST_START,
  SEARCH_STOCK_LIST_SUCCESS,
  SEARCH_STOCK_LIST_ERROR,
  GET_TM_JOBS_START,
  GET_TM_JOBS_SUCCESS,
  GET_TM_JOBS_ERROR,
  GET_RQ_JOBS_START,
  GET_RQ_JOBS_SUCCESS,
  GET_RQ_JOBS_ERROR,
  GET_INTERNAL_JOBS_START,
  GET_INTERNAL_JOBS_SUCCESS,
  GET_INTERNAL_JOBS_ERROR,
} from '../constantTypes';

const initialState = {
  loading: false,
  loaded: false,
  isError: false,
  errors: [],
  list: [],
  vendors: [],
  vendor: [],
  fullDataList: [],
  value: "",
  tmJobs: [],
  rqJobs: [],
  internalJobs: [],
};

const stockListReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TM_JOBS_START:
      return {...state, loading: true, loaded: false, isError: false};
    case GET_TM_JOBS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        isError: false,
        tmJobs: action.payload,
      };
    case GET_TM_JOBS_ERROR:
      return {...state, loading: false, loaded: false, isError: true};
    case GET_RQ_JOBS_START:
      return {...state, loading: true, loaded: false, isError: false};
    case GET_RQ_JOBS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        isError: false,
        rqJobs: action.payload,
      };
    case GET_RQ_JOBS_ERROR:
      return {...state, loading: false, loaded: false, isError: true};

    case GET_INTERNAL_JOBS_START:
      return {...state, loading: true, loaded: false, isError: false};
    case GET_INTERNAL_JOBS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        isError: false,
        internalJobs: action.payload,
      };
    case GET_INTERNAL_JOBS_ERROR:
      return {...state, loading: false, loaded: false, isError: true};

    case FETCH_STOCK_LIST_START:
      return {
        ...state,
        loading: true,
      };
    case FETCH_STOCK_LIST_SUCCESS:
      /**
       * Create vendor object for Add_new_part
       * @param item
       * @returns {{stock_list_items: Array, qty: string, part_id: *}}
       */
      const createOrder = item => {
        return {
          checked: false,
          part_id: item['id'],
          quantity: '',
          stock_list_items: [],
        };
      };

      return {
        ...state,
        vendor: action.payload,
        list: action.payload,
        vendors: R.map(createOrder, action.payload.items),
        loading: false,
      };
    case FETCH_STOCK_LIST_ERROR:
      return {
        ...state,
        isError: true,
        errors: action.payload,
        loading: false,
      };
    case SELECT_VENDOR:
      const id = action.payload.id;
      const checked = action.payload.checked;
      const findedItem = R.find(R.propEq('part_id', id), state.vendors);
      const orderPosition = R.indexOf(findedItem, state.vendors);
      findedItem.checked = checked;

      return {
        ...state,
        loading: true,
        vendors: R.update(orderPosition, findedItem, state.vendors),
      };
    case CHANGE_VENDOR_QTY:
      const qtyId = action.payload.id;
      const quantity = action.payload.quantity;
      const qtyFindedItem = R.find(
        R.propEq('part_id', qtyId),
        state.vendors
      );
      const qtyOrderPosition = R.indexOf(qtyFindedItem, state.vendors);
      qtyFindedItem.quantity = quantity;

      return {
        ...state,
        vendors: R.update(
          qtyOrderPosition,
          qtyFindedItem,
          state.vendors
        ),
        loading: false,
      };
    case CHANGE_VENDOR_NAME:
      const nameId = action.payload.id;
      const value = Number(action.payload.value);
      const nameFindedItem = R.find(
        R.propEq('part_id', nameId),
        state.vendors
      );
      const nameOrderPosition = R.indexOf(nameFindedItem, state.vendors);
      const {stock_list_items} = nameFindedItem;
      const filtered = elem => !R.equals(elem, value);

      if (!R.includes(value, stock_list_items)) {
        nameFindedItem.stock_list_items.push(value);
      } else {
        nameFindedItem.stock_list_items = R.filter(
          filtered,
          nameFindedItem.stock_list_items
        );
      }

      return {
        ...state,
        vendors: R.update(
          nameOrderPosition,
          nameFindedItem,
          state.vendors
        ),
        loading: false,
      };
    case GET_FULL_STOCK_LIST_DATA_START:
      return {
        ...state,
        loading: true,
      };
    case GET_FULL_STOCK_LIST_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: [],
        fullDataList: action.payload,
      };
    case GET_FULL_STOCK_LIST_DATA_ERROR:
      return {
        ...state,
        errors: action.payload,
      };
    case SEARCH_STOCK_LIST_START:
      return {
        ...state,
      };
    case SEARCH_STOCK_LIST_SUCCESS:
      const createOrderWithSearch = item => {
        return {
          checked: false,
          part_id: item['id'],
          quantity: '',
          stock_list_items: [],
        };
      };

      return {
        ...state,
        vendor: action.payload,
        list: action.payload,
        vendors: R.map(createOrderWithSearch, action.payload.items),
        loading: false,
      };
    case SEARCH_STOCK_LIST_ERROR:
      return {
        ...state,
        errors: action.payload,
      };
    default:
      return state;
  }
};

export default stockListReducer;
