import {
  GET_TIER_LIST_START,
  GET_TIER_LIST_SUCCESS,
  GET_TIERLIST_ERROR,
  GET_COST_TYPE_LIST_START,
  GET_COST_TYPE_LIST_SUCCESS,
  GET_COST_TYPE_LIST_ERROR
} from '../constantTypes';

const initialState = {
  loading: false,
  tierListErrors: [],
  tiers: [],
  cost_type: [],
  costTypeErrors: []
};


const jeneralArraysReducer = (state = initialState, action) => {
  switch (action.type) {
      case GET_TIER_LIST_START:
          return {
              ...state,
              loading: true,
          };
      case GET_TIER_LIST_SUCCESS:
          return {
              ...state,
              tiers: action.payload,
              loading: false,
          };
      case GET_TIERLIST_ERROR:
          return {
              ...state,
              errors: action.payload,
              loading: false,
          };
          case GET_COST_TYPE_LIST_START:
            return {
              ...state,
              loading: true,
            };
            case GET_COST_TYPE_LIST_SUCCESS:
            return {
              ...state,
              cost_type: action.payload,
              loading: false
            }

          case GET_COST_TYPE_LIST_ERROR:
            return {
              ...state,
              costTypeErrors: action.payload,
              loading: false,
            }
      default:
          return state;
  }
};

export default jeneralArraysReducer;


