import {
  GET_USERS_TIME_LIST,
  SET_LOGIN_BY_PIN_ERROR,
  SET_CURRENT_USER,
  RESET_ERRORS,
  ENTER_PIN,
  ENTER_UID,
  GET_DAILY_CARD_DATA,
  CLOCK_OUT,
  GET_USER_NAME,
  SET_LUNCH_ERROR,
  SUBMIT_HOURS_ERROR,
  SET_DIALY_REVIEWS,
  LOGOUT_TIME_MANAGEMENT,
  GET_WEEKLY_REVIEWS,
  GET_WEEKLY_REVIEW,
  GET_WEEKLY_HISTORY,
  GET_WEEKLY_EMPLOYEES,
  UPDATE_WEEKLY_REVIEW,
  WEEKLY_REVIEW_MODAL,
  REMOVE_WEEKLY_REVIEWS,
  EXPORT_REPORT,
  PARAMS_FOR_EXPORT_REPORT,

} from '../constantTypes'

const initialState = {
    usersList: [],
    currentUser: {},
    currentPin: null,
    currentUid: null,
    dialyReviews: null,
    weeklyReviews: null,
    weeklyReview: null,
    weeklyHistory: null,
    weeklyEmployees:null,
    errors: {
        pinError: null,
    },
    weekTimeCardModal:false,
    hoursHistory:null,
    paramsForReport:null
};

const timeManagementReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_USERS_TIME_LIST:
            return {
                ...state,
                usersList: [...action.payload],
            }
        case SET_LOGIN_BY_PIN_ERROR:
            return {
                ...state,
                errors: {
                    pinError: action.payload
                }
            }
        case SET_CURRENT_USER:
            return {
                ...state,
                currentUser: action.payload,
            }
        case RESET_ERRORS:
            return {
                ...state,
                errors: {},
            }
        case ENTER_PIN:
            return {
                ...state,
                currentPin: action.payload,
            }
        case ENTER_UID:
            return {
                ...state,
                currentUid: action.payload,
            }
        case GET_DAILY_CARD_DATA:
            return {
                ...state,
                dailyTimeCard: action.payload,
            }
        case CLOCK_OUT:
            return {
                ...state,
                currentUser: {},
            }
        case GET_USER_NAME:
            return {
                ...state,
                firstName: action.payload.first_name,
                lastName: action.payload.last_name,
            }
        case SET_LUNCH_ERROR:
            return {
                ...state,
                errors: {
                    lunchError: action.payload
                }
            }
        case SUBMIT_HOURS_ERROR:
            return {
                ...state,
                errors: {
                    submitHoursError: action.payload,
                }
            }
        case SET_DIALY_REVIEWS:
            return {
                ...state,
                dialyReviews: action.payload
            }
        case LOGOUT_TIME_MANAGEMENT:
            return {
                ...state,
                currentUser: {},
            }
        case GET_WEEKLY_REVIEWS:
            return {
                ...state,
                weeklyReviews: action.payload,
            }
        case GET_WEEKLY_REVIEW:
          return {
            ...state,
            weeklyReview: action.payload,
          }
      case GET_WEEKLY_HISTORY:
        return {
          ...state,
          weeklyHistory: action.payload,
        }
      case PARAMS_FOR_EXPORT_REPORT :
        return {
          ...state,
          paramsForReport:action.payload
        }
      case GET_WEEKLY_EMPLOYEES:
        return {
          ...state,
          weeklyEmployees:action.payload
        }
      case UPDATE_WEEKLY_REVIEW :
        return {
          ...state,
          weeklyUpdate : action.payload,
          weekTimeCardModal:true
        }
      case WEEKLY_REVIEW_MODAL :
          return {
            ...state,
            weeklyReview: null,
            weekTimeCardModal:false,
            weeklyReviews:null
          }
      case REMOVE_WEEKLY_REVIEWS :
        return {
          ...state,
          weeklyReviews : null
        }
      case EXPORT_REPORT :
        return {
          ...state,
          hoursHistory:action.payload
        }
        default:
            return state;
    }
}

export default timeManagementReducer;
