import {
    SEARCH_RFQ_START,
    SEARCH_RFQ_SUCESS,
    SEARCH_RFQ_ERROR,
} from '../constantTypes';

const initialState = {
    loading: true,
    list: false,
    errors: [],
    items: [],
    value: '',
};

const rfqReducer = (state = initialState, action) => {
    switch (action.type) {
        case SEARCH_RFQ_START:
            return {
                ...state,
            };
          case SEARCH_RFQ_SUCESS:
            return {
              ...state,
              loading: false,
              list: action.payload,
              items: action.items,
              value: action.value
            }
          case SEARCH_RFQ_ERROR:
            return {
              ...state,
              errors: action.payload
            }
        default:
            return state;
    }
};

export default rfqReducer;
