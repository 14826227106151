import {
  SEARCH_TAXES_START,
  SEARCH_TAXES_SUCCESS,
  SEARCH_TAXES_ERROR,
} from '../constantTypes';

const initialState = {
  loading: true,
  list: false,
  errors: [],
  value: '',
};

const taxesReducer = (state = initialState, action) => {
  switch(action.type) {
    case SEARCH_TAXES_START:
      return {
        ...state
      }
    case SEARCH_TAXES_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
        value: action.value
      }
    case SEARCH_TAXES_ERROR:
    return {
      ...state,
      errors: action.payload
    }
    default:
      return state;
  }
};

export default taxesReducer;
