import {
  SEARCH_CURRENCY_START,
  SEARCH_CURRENCY_SUCESS,
  SEARCH_CURRENCY_ERROR
} from "../constantTypes";

const initialState = {
  loading: true,
  list: false,
  errors: [],
  value: ""
};
const currencyReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_CURRENCY_START:
      return {
        ...state
      }
    case SEARCH_CURRENCY_SUCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
        value: action.value
      }
    case SEARCH_CURRENCY_ERROR:
      return {
        ...state,
        error: action.payload
      }
    default:
      return state;
  }
};

export default currencyReducer;
