import {
  FETCH_AUTH_START,
  FETCH_AUTH_SUCCESS,
  FETCH_AUTH_ERROR,
  LOGOUT_AUTH,
  SET_MY_PERMISSIONS_IN_REDUX,
} from '../constantTypes';

const initialState = {
  loading: false,
  isError: false,
  errors: [],
  isLogin: false,
  access_token: '',
  permissions: [],
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_AUTH_START:
      return {
        ...state,
        loading: true,
      };
    case FETCH_AUTH_SUCCESS:
      return {
        ...state,
        isLogin: true,
        access_token: action.payload.access_token,
        loading: false
      };
    case FETCH_AUTH_ERROR:
    case SET_MY_PERMISSIONS_IN_REDUX:
      return {
        ...state,
        permissions: action.payload,
      };
    case LOGOUT_AUTH:
      return {
        ...state,
        isError: false,
        loading: false,
        access_token: ''
      };
    default:
      return state
  }
};

export default authReducer;

