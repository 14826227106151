import {
  GET_RECEIVING_TABLE_LIST_INIT,
  GET_RECEIVING_TABLE_LIST_SUCCESS,
  GET_RECEIVING_TABLE_LIST_FAILURE,

  GET_RECEIVING_DETAILS_INIT,
  GET_RECEIVING_DETAILS_SUCCESS,
  GET_RECEIVING_DETAILS_FAILURE,

  GET_RECEIVING_ITEMS_INIT,
  GET_RECEIVING_ITEMS_SUCCESS,
  GET_RECEIVING_ITEMS_FAILURE,

  GET_RECEIVING_HISTORY_INIT,
  GET_RECEIVING_HISTORY_SUCCESS,
  GET_RECEIVING_HISTORY_FAILURE,

  ADD_RECEIVING_DETAILS_INIT,
  ADD_RECEIVING_DETAILS_SUCCESS,
  ADD_RECEIVING_DETAILS_FAILURE,

  GET_RECEIVING_FILES_INIT,
  GET_RECEIVING_FILES_SUCCESS,
  GET_RECEIVING_FILES_FAILURE,

  REMOVE_RECEIVING_ERROR,
} from '../constantTypes';

import {combineReducers} from 'redux';

const receivingState = {
  loading: false,
  loaded: false,
  error: false,
  data: [],
};

function receivingReducer(state = receivingState, { type, payload }) {
  switch (type) {
    case GET_RECEIVING_TABLE_LIST_INIT:
      return {...state, loading: true, loaded: false, error: false};
    case GET_RECEIVING_TABLE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        data: payload,
      };
    case GET_RECEIVING_TABLE_LIST_FAILURE:
      return {...state, loading: false, loaded: false, error: payload};
    default:
      return state;
  }
}


const receivingDetailsState = {
  loading: false,
  loaded: false,
  error: false,
  data: [],
};

function receivingDetailsReducer(state = receivingDetailsState, { type, payload }) {
  switch (type) {
    case GET_RECEIVING_DETAILS_INIT:
      return {...state, loading: true, loaded: false, error: payload};
    case GET_RECEIVING_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        data: payload,
      };
    case GET_RECEIVING_DETAILS_FAILURE:
      return {...state, loading: false, loaded: false, error: payload};
    default:
      return state;
  }
}

const receivingItemsState = {
  loading: false,
  loaded: false,
  error: false,
  files: [],
  data: [],
};

function receivingItemsReducer(state = receivingItemsState, { type, payload }) {
  switch (type) {
    case GET_RECEIVING_ITEMS_INIT:
      return {...state, loading: true, loaded: false, error: false};
    case GET_RECEIVING_ITEMS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        data: payload,
      };
    case GET_RECEIVING_ITEMS_FAILURE:
      return {...state, loading: false, loaded: false, error: payload};

    case GET_RECEIVING_FILES_INIT:
      return {...state, loading: true, loaded: false, error: false};
    case GET_RECEIVING_FILES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        files: payload,
      };
    case GET_RECEIVING_FILES_FAILURE:
      return {...state, loading: false, loaded: false, error: payload};
    default:
      return state;
  }
}

const receivingHistoryState = {
  loading: false,
  loaded: false,
  error: false,
  errors: null,
  data: [],
};

function receivingHistoryReducer(state = receivingHistoryState, { type, payload }) {
  switch (type) {
    case GET_RECEIVING_HISTORY_INIT:
      return {...state, loading: true, loaded: false, error: false};
    case GET_RECEIVING_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        data: payload,
      };
    case GET_RECEIVING_HISTORY_FAILURE:
      return {...state, loading: false, loaded: false, error: payload};

    case ADD_RECEIVING_DETAILS_INIT:
      return {...state, loading: true, loaded: false, error: false};
    case ADD_RECEIVING_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        data: payload,
      };
    case ADD_RECEIVING_DETAILS_FAILURE:
      return {...state, loading: false, loaded: false, error: true, errors: payload};
    case REMOVE_RECEIVING_ERROR:
      return {errors: null};

    default:
      return state;
  }
}

export default combineReducers({
  list: receivingReducer,
  details: receivingDetailsReducer,
  items: receivingItemsReducer,
  history: receivingHistoryReducer,
});
