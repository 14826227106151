import * as R from 'ramda';
import {
  ADD_NEW_VENDOR,
  DELETE_NEW_VENDOR
} from '../constantTypes';

const initialState = {
  vendors: []
};

const vendorReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_NEW_VENDOR:
      return {
        ...state,
        loading: true,
        vendors: state.vendors.push(action.payload)
      };
    case DELETE_NEW_VENDOR:
      const filtered = el => !R.equals(el.id, action.payload)
      return {
        ...state,
        vendors: R.filter(filtered, state.vendors),
        loading: false
      };
    default:
      return state
  }
};

export default vendorReducer;

