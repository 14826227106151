import {
    ADD_NEW_TIER_START,
    ADD_NEW_TIER_SUCCESS,
    ADD_NEW_TIER_ERROR,
    GET_TIER_MANAGMENT_LIST_START,
    GET_TIER_MANAGMENT_LIST_SUCCES,
    GET_TIER_MANAGMENT_LIST_ERROR,
    DELETE_TIER_MANAGMENT_ITEM_START,
    DELETE_TIER_MANAGMENT_ITEM_SUCCES,
    DELETE_TIER_MANAGMENT_ITEM_ERROR,
    PUT_NEW_TIER_START,
    PUT_NEW_TIER_ERROR,
    PUT_NEW_TIER_SUCCESS,
    GET_TIER_MANAGMENT_ALL_DATA_LIST_START,
    GET_TIER_MANAGMENT_ALL_DATA_LIST_SUCCESS,
    GET_TIER_MANAGMENT_ALL_DATA_LIST_ERROR
} from '../constantTypes';
import * as R from 'ramda';

const initialState = {
    loading: false,
    isError: false,
    list: [],
    errors: [],
    items: [],
    fullList:[]
};

const tierReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_NEW_TIER_START:
            return {
                ...state,
                loading: true,
            };
        case ADD_NEW_TIER_SUCCESS:
            return {
                ...state,
                // list: state.list.concat(action.payload) ,
                items: action.payload,
                loading: false,
                errors: [],
                validationMistakes: []
            };
        case ADD_NEW_TIER_ERROR:
            return {
                ...state,
                errors: action.payload,
                loading: false,
            };

        case GET_TIER_MANAGMENT_LIST_START:
            return {
                ...state,
                loading: true,
                errors: [],
                validationMistakes: []
            };
        case GET_TIER_MANAGMENT_LIST_SUCCES:
            return {
                ...state,
                loading: false,
                list: action.payload,
                errors: [],
                validationMistakes: []
            };
        case GET_TIER_MANAGMENT_LIST_ERROR:
            return {
                errors: action.payload,
                loading: false,
                validationMistakes: action.payload
            };
        case DELETE_TIER_MANAGMENT_ITEM_START:
            return {
                ...state,
                loading: true,
            };
        case DELETE_TIER_MANAGMENT_ITEM_SUCCES:
            const filtered = el => !R.equals(el.id, action.payload);
            return {
                ...state,
                list: R.filter(filtered, state.list),
                loading: false,
            };
        case DELETE_TIER_MANAGMENT_ITEM_ERROR:
            return {
                ...state,
                errors: action.payload,
            };
            case PUT_NEW_TIER_START:
              return {
                  ...state,
                  loading: true,
              };
          case PUT_NEW_TIER_SUCCESS:
              return {
                  ...state,
                  items: action.payload,
                  loading: false,
                  errors: [],
              };
          case PUT_NEW_TIER_ERROR:
              return {
                  ...state,
                  errors: action.payload,
                  loading: false,
              };
              case GET_TIER_MANAGMENT_ALL_DATA_LIST_START:
                return {
                    ...state,
                    loading: true,
                };
            case GET_TIER_MANAGMENT_ALL_DATA_LIST_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    fullList: action.payload,
                };
            case GET_TIER_MANAGMENT_ALL_DATA_LIST_ERROR:
                return {
                    errors: action.payload,
                };

        default:
        return state;
    };
};

export default tierReducer;
