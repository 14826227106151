import { OPEN_MODAL, CLOSE_MODAL } from '../constantTypes';
const initialState = {
    someModalIsOpen: false,
    isEdit: false,
    isOpenEdit: false,
    isOpenHomeCurrency: false,
    isOpenAddVendor: false,
    isOpenProductType: false,
    isOpenEditStock: false,
    isOpenDeleteStock: false,
    isOpenEditVendor: false,
    isOpenProductTypeNewPart: false,
    isOpenProductTypeUploadFromXLS: false,
    isOpenStockListSelectProject: false,
    isOpenNewTax: false,
    isOpenEditTax: false,
    isOpenAddNewParentQuote: false,
    isOpenAddNewParentQuoteSecondStep: false,
    isOpenAddNewParentThirdLine: false,
    isOpenAddNewChildQuote: false,
    isOpenAddNewCutTime: false,
    isOpenLabourType: false,
    isOpenLabourTypeEdit: false,
    isOpenAddFromStockListModal: false,
    isOpenEditChildQuote: false,
    isOpenEditCutTime: false,
    isOpenEditTierModal: false,
    isOpenAddNewVendorPartModal: false,
    isOpenEditNewVendor: false,
    isOpenAddNewTierModal: false,
    isOpenEditNewTierModal: false,
    isOpenEditLabourTypeModal: false,
    isOpenEditVendorToPart: false,
    isOpenJobModal: false,
};

function modalReducer(state = initialState, action) {
    let payload = {};
    if (action.issue_type) {
        payload.issue_type = action.issue_type;
    }
    switch (action.type) {
        case OPEN_MODAL:
            return {
                ...state,
                [action.params.modalType]: true,
                someModalIsOpen: true,
                payload: payload,
            };

        case CLOSE_MODAL:
            return {
                ...state,
                [action.params.modalType]: false,
                someModalIsOpen: false,
            };
        default:
            return state;
    }
}

export default modalReducer;
