import {
    GET_MATERIAL_DETAILS_LIST_START,
    GET_MATERIAL_DETAILS_LIST_SUCCESS,
    GET_MATERIAL_DETAILS_LIST_ERROR,
    POST_MATERIAL_DETAILS_DATA_START,
    POST_MATERIAL_DETAILS_DATA_SUCCESS,
    POST_MATERIAL_DETAILS_DATA_ERROR,
    DELETE_MATERIAL_DETAILS_ITEM_START,
    DELETE_MATERIAL_DETAILS_ITEM_SUCCESS,
    DELETE_MATERIAL_DETAILS_ITEM_ERROR,
    GET_MATERIAL_DETAILS_CALCULATIONS_START,
    GET_MATERIAL_DETAILS_CALCULATIONS_SUCCESS,
    GET_MATERIAL_DETAILS_CALCULATIONS_ERROR,
    POST_MATERIAL_DETAILS_FROM_STOCK_LIST_START,
    POST_MATERIAL_DETAILS_FROM_STOCK_LIST_SUCCESS,
    POST_MATERIAL_DETAILS_FROM_STOCK_LIST_ERROR,
} from '../constantTypes';
import * as R from 'ramda';

const initialState = {
    list: [],
    errors: [],
    loading: false,
    calculations: [],
};

const materialDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_MATERIAL_DETAILS_LIST_START:
            return {
                ...state,
                loading: true,
            };
        case GET_MATERIAL_DETAILS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                list: action.payload,
                errors: [],
            };
        case GET_MATERIAL_DETAILS_LIST_ERROR:
            return {
                ...state,
                errors: action.payload,
            };
        case POST_MATERIAL_DETAILS_DATA_START:
            return {
                ...state,
                errors: [],
                loading: true,
            };
        case POST_MATERIAL_DETAILS_DATA_SUCCESS:
            let copy = [...state.list];
            return {
                ...state,
                loading: false,
                list: copy.concat(action.payload),
            };
        case POST_MATERIAL_DETAILS_DATA_ERROR:
            return {
                ...state,
                errors: action.payload,
            };
        case DELETE_MATERIAL_DETAILS_ITEM_START:
            return {
                ...state,
                loading: true,
            };
        case DELETE_MATERIAL_DETAILS_ITEM_SUCCESS:
            const filtered = el => !R.equals(el.id, action.payload);
            return {
                ...state,
                loading: false,
                errors: [],
                list: R.filter(filtered, state.list),
            };
        case DELETE_MATERIAL_DETAILS_ITEM_ERROR:
            return {
                ...state,
                errors: action.payload,
            };
        case GET_MATERIAL_DETAILS_CALCULATIONS_START:
            return {
                ...state,
                loading: true,
            };
        case GET_MATERIAL_DETAILS_CALCULATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                calculations: action.payload,
            };
        case GET_MATERIAL_DETAILS_CALCULATIONS_ERROR:
            return {
                ...state,
                errors: action.payload,
            };
        case POST_MATERIAL_DETAILS_FROM_STOCK_LIST_START:
            return {
                ...state,
                loading: true,
            };

        case POST_MATERIAL_DETAILS_FROM_STOCK_LIST_SUCCESS:
            const newListCopy = [...state.list];
            const materialDetailsFromStockList = newListCopy.concat(
                action.list
            );
            return {
                ...state,
                calculations: action.calculations,
                list: materialDetailsFromStockList,
                loading: false,
            };
        case POST_MATERIAL_DETAILS_FROM_STOCK_LIST_ERROR:
            return {
                ...state,
                errors: action.payload,
            };
        default:
            return state;
    }
};

export default materialDetailsReducer;
