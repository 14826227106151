import {
  FETCH_COUNTRIES_START,
  FETCH_COUNTRIES_SUCCESS,
  FETCH_COUNTRIES_ERROR,
} from '../constantTypes';

const initialState = {
  loading: false,
  isError: false,
  errors: [],
  countries: []
};

const countriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COUNTRIES_START:
      return {
        ...state,
        loading: true,
      };
      case FETCH_COUNTRIES_SUCCESS:
        return {
          ...state,
          countries: action.payload,
          loading: false
        };
        case FETCH_COUNTRIES_ERROR:
          return {
            ...state,
            isError: true,
            errors: action.payload,
            loading: false
          };
          default:
            return state
          }
        };
     
export default countriesReducer;

